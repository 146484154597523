import React from 'react';
import { useHistory } from 'react-router-dom';
import { PermissionList, renderClientName, RolePermission, SaveParams, useModal } from 'utils';
import { AddButton, DateTypeSelect, DateTypeSelector, DownloadCsv, SelectAutocompletePagination } from 'components';
import { GenerateInvoice } from './generateInvoice';

export const InvoiceFilters = ({}) => {
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const { open } = useModal();

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        info.skip = 0;
        info.limit = 50;
        info.page = 1;
        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
            if (e.target.name === 'client') {
                info['clientFull'] = e.target?.full;
            }
        }
        SaveParams(history, { ...info });
    };

    return (
        <div className="table-invoice-filter-section">
            <div className="billing-invoices-filter-box">
                <SelectAutocompletePagination
                    outLabel={'Client'}
                    name={'client'}
                    handleSelect={handleChange}
                    placeholder={pushInfo?.clientFullName ? pushInfo?.clientFullName : 'All'}
                    defaultValue={pushInfo?.client}
                    renderValue={(i) => renderClientName(i)}
                    type={'client'}
                    uiType={'tableFilter'}
                    noError={true}
                    currentSelected={pushInfo?.clientFull && pushInfo?.clientFull}
                />
                <DateTypeSelector
                    startName={'from'}
                    endName={'to'}
                    outLabel={'DOS'}
                />
                <DateTypeSelect
                    name={'invoiceDate'}
                    noError={true}
                    outLabel={'Invoice Date'}
                />
            </div>
            <div className="billing-invoices-filter-box">
                <DownloadCsv smallSize={true} type={'billingInvoice'} fromModal={true} />
                {RolePermission([PermissionList.INVOICE_CREATE?.code]) &&
                    <AddButton
                        text={'Generate Invoice'}
                        handleClick={() => open(<GenerateInvoice />)}
                    />
                }
            </div>
        </div>
    );
};