import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
    CreateChancel,
    SelectAutocompleteMultiple,
    NumericFormatCustom,
    DateCalendarForm,
    ErrMessage, Warning,
} from 'components';
import { FindErrorItem, FindLoad, FindSuccessItem, useModal } from 'utils';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { createClientStyle } from 'fragments/client';
import { staffModalsStyle } from './styles';

const EDIT_ACTION_TYPE = 'EDIT_PAY_CODE'
export const PaycodeModal = ({ item, requestParams }) => {
    const ACTION_TYPE = item?.id ? 'EDIT_PAY_CODE' : 'CREATE_PAY_CODE';
    const { handleSubmit, control, clearErrors, watch, reset } = useForm({
        defaultValues: {
            payCodeTypeId: '',
            rate: '',
            startDate: '',
            endDate: '',
        },
    });
    const { staff, payCodes, globalPayCodes } = useSelector((state) => ({
        staff: state.admins.adminInfoById,
        payCodes: state.admins.payCodes,
        globalPayCodes: state.payroll.payCodes,
    }));
    const { close } = useModal();
    const classes = createClientStyle();
    const classes_v2 = staffModalsStyle();
    const dispatch = useDispatch();
    const success = FindSuccessItem(EDIT_ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const currentPaycode = globalPayCodes?.find((i) => i?.id === watch('payCodeTypeId'));
    const currentPayCodeUsed = payCodes?.find((i) => i?.payCodeTypeId?._id === watch('payCodeTypeId') && !i?.endDate);
    const checkEndDate =
        item?.id ? currentPayCodeUsed ? !!item?.endDate : false :
        currentPayCodeUsed ? !currentPayCodeUsed?.endDate : false;

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ACTION_TYPE));
        }
        if (item?.id) {
            reset({
                payCodeTypeId: item?.payCodeTypeId?._id,
                rate: item?.rate,
                startDate: moment.utc(item?.startDate).format('YYYY-MM-DD'),
                endDate: item?.endDate ? moment.utc(item?.endDate).format('YYYY-MM-DD') : '',
            });
        }
    }, [success, item]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    function handleCreate(data) {
        if (backError?.error) {
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        }
        const sendData = { ...data };
        if (!data?.endDate) {
            delete sendData.endDate;
        }
        sendData.staffId = staff?.id;

        if (item?.id) {
            dispatch(adminActions.editPayCode(sendData, staff?.id, item?.id, requestParams));
        } else {
            dispatch(adminActions.createPayCode(sendData, staff?.id));
        }
    }

    return (
        <form
            onSubmit={handleSubmit(handleCreate)}
        >
            <h1 className="modal-header-title">
                {item ? 'Edit Paycode' : 'Add New Paycode'}
            </h1>

            <div className="modal-body-wrapper" style={{marginBottom: 0}}>
                <div className={classes.clientModalBox}>
                    <SelectAutocompleteMultiple
                        defaultSelected={item?.payCodeTypeId && { name: item?.payCodeTypeId?.name }}
                        name="payCodeTypeId"
                        label={'Paycode name*'}
                        control={control}
                        options={globalPayCodes}
                        // options={currentPayCodes}
                        rules={{
                            required: 'Paycode name is required.',
                        }}
                        renderValue={(option) => option?.name}
                    />
                    {currentPaycode?.id &&
                        <div className={classes_v2.codeAndTypeBoxStyle}
                             style={{ height: 'fit-content', padding: '12px 16px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                                <div className={classes_v2.paycodeBox}>
                                    <p className={classes_v2.paycodeBoxTitle}>Code:</p>
                                    <p className={classes_v2.paycodeBoxText}>
                                        {currentPaycode?.code ? currentPaycode?.code : ' N/A'}
                                    </p>
                                </div>
                                <div
                                    className={classes_v2.paycodeBox}
                                    style={{ marginBottom: 0 }}
                                >
                                    <p className={classes_v2.paycodeBoxTitle}>Type:</p>
                                    <p className={classes_v2.paycodeBoxText}>
                                        {currentPaycode?.type ? currentPaycode.type : 'N/A'}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    <NumericFormatCustom
                        name={'rate'}
                        label={'Rate'}
                        prefix={'$'}
                        thousandSeparator={true}
                        fixedDecimalScale={true}
                        decimalScale={2}
                        control={control}
                        rules={{
                            required: 'Rate is required. Please enter the rate.',
                        }}
                    />
                    <DateCalendarForm
                        name="startDate"
                        label={'Start Date*'}
                        max={watch('endDate') ? moment.utc(watch('endDate')).format('YYYY-MM-DD') : ''}
                        control={control}
                        rules={{ required: 'Start date is required.' }}
                    />
                    <DateCalendarForm
                        name="endDate"
                        label={`End Date${checkEndDate ? '*' : ''}`}
                        min={watch('startDate') ? moment.utc(watch('startDate')).format('YYYY-MM-DD') : ''}
                        control={control}
                        rules={{ required: checkEndDate ? 'End date is required.' : false }}
                    />

                    <ErrMessage text={backError?.error ? backError?.error : ''} styles={{ fontSize: 14 }} />

                    {!checkEndDate &&
                        <Warning
                            text={'Only one payrate can be added without an end date'}
                            styles={{ alignItems: 'center' }}
                        />
                    }
                </div>
            </div>
            <div className={classes.clientModalBlock}>
                <CreateChancel
                    type="submit"
                    loader={!!loader.length}
                    create={item?.id ? 'Save' : 'Add'}
                    chancel={'Cancel'}
                    onClose={close}
                    buttonWidth="224px"
                />
            </div>
        </form>
    );
};