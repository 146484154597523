import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { modalsStyle } from 'components/modal/styles';
import { manageType, FindLoad, FindSuccess, useModal } from 'utils';
import {
    RadioButton,
    CreateChancel,
    SelectAutocompleteMultiple, Input, DateCalendarForm,
} from 'components';
import { adminActions, httpRequestsOnErrorsActions } from 'store';
import { staffModalsStyle } from './styles';
import { FBI, TB } from '../constants';
import { useForm } from 'react-hook-form';

const radioData = [
    {
        label: 'Non-Expiring',
        value: 'nonExpiring',
    },
    {
        label: 'Expiring',
        value: 'expiring',
    },
];

const checkboxStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
};

export const CredentialModal = ({ item }) => {
    const { globalCredentials, staff } = useSelector((state) => ({
        globalCredentials: state.system.credentials,
        staff: state.admins.adminInfoById,
    }));
    const ACTION_TYPE = !!item ? 'EDIT_CREDENTIAL_BY_ID' : 'CREATE_CREDENTIAL';
    const dispatch = useDispatch();
    const classes = modalsStyle();
    const classes_v2 = staffModalsStyle();
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const [checkboxValue, setCheckboxValue] = useState(!!item?.expirationDate ? 'expiring' : 'nonExpiring');
    const staffCredentialList = useSelector((state) => state.admins.credential);
    const { close } = useModal();

    const {
        handleSubmit,
        control,
        clearErrors,
        // setError,
        reset,
        watch,
    } = useForm({
        defaultValues: {
            credentialId: '',
            licenseNumber: '',
            receiveDate: '',
            expirationDate: '',
        },
    });

    const filteredCredentials = globalCredentials?.filter(gCredential => (
        !staffCredentialList?.credentials?.some((sCredential) =>
            (sCredential?.credential?._id === gCredential?.id)
            && (sCredential?.credential?._id !== watch('credentialId')?.id),
        )
    ));


    useEffect(() => {
        if (item?.id) {
            const params = {
                credentialId: globalCredentials?.find((gCredential) => gCredential?.id === item?.credential?._id),
                licenseNumber: item?.licenseNumber,
            };

            if (item?.receiveDate) params.receiveDate = moment.utc(item.receiveDate).format('YYYY-MM-DD');
            if (item?.expirationDate) params.expirationDate = moment.utc(item.expirationDate).format('YYYY-MM-DD');
            if (item?.licenseNumber) params.licenseNumber = item?.licenseNumber;

            reset(params);

        }
    }, [item]);

    useEffect(() => {
        if (!!success.length) {
            close();
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);


    const change = (event) => {
        setCheckboxValue(event.target.value);
    };

    const handleCreate = (data) => {
        const sandedData = {
            staffId: staff?.id,
            credentialId: data?.credentialId?.id,
            licenseNumber: data?.licenseNumber,
            expirationDate: checkboxValue === 'nonExpiring' ? null : data.expirationDate ? new Date(data?.expirationDate).toISOString() : null,
            receiveDate: data?.receiveDate ? new Date(data.receiveDate).toISOString() : null,
        };

        if (item) {
            dispatch(adminActions.editCredentialById(sandedData, item?.id, staff?.id));
        } else {
            dispatch(adminActions.createCredential(sandedData));
        }
    };

    return (
        <form
            style={{ width: 460 }}
            onSubmit={handleSubmit(handleCreate)}
        >
            <p className="modal-header-title">   {item ? 'Edit Credential' : 'Add a New Credential'}</p>
            <div className="modal-body-wrapper">
                <SelectAutocompleteMultiple
                    defaultSelected={item?.credential && { name: item?.credential?.name, type: item?.credential?.type}}
                    name="credentialId"
                    label={'Select Credential*'}
                    control={control}
                    options={filteredCredentials}
                    disabled={item?.id ? (item?.type === FBI || item?.type === TB) : false}
                    rules={{ required: 'Credential is required.' }}
                    renderValue={(i) => `${i?.name ? i?.name : ''}${i?.type ? ` (${manageType(i.type)})` : ''}`}
                    fullValue={true}
                />
                <div className={classes_v2.credentialTypeBoxStyle}>
                    <p className={classes_v2.paycodeBoxTitle}>Type:</p>
                    <p className={classes_v2.paycodeBoxText}>
                        {watch('credentialId')?.type ? manageType(watch('credentialId')?.type) : 'N/A'}
                    </p>
                </div>
                <Input
                    name="licenseNumber"
                    label="License Number"
                    variant="outlined"
                    control={control}
                    maxLength={30}
                    rules={{
                        maxLength: {
                            value: 30,
                            message: 'Max length is 30 digits.',
                        },
                    }}
                />
                <DateCalendarForm
                    name="receiveDate"
                    label={'Received Date'}
                    control={control}
                />

                {item?.type !== FBI && item?.type !== TB ?
                    <>
                        <p className={classes.title}>Expiration</p>
                        <div className={classes.checkboxWrapper}>
                            <RadioButton
                                styles={checkboxStyle}
                                value={checkboxValue}
                                onChange={change}
                                radioData={radioData}
                            />

                            {checkboxValue !== 'nonExpiring' && (
                                <div style={{ marginTop: 20 }}>
                                    <DateCalendarForm
                                        name="expirationDate"
                                        label={'Expiration Date*'}
                                        control={control}
                                        rules={{
                                            required: 'Expiration date is required.',
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </>
                    :
                    ''
                }
            </div>
            <CreateChancel
                type="submit"
                loader={!!loader.length}
                buttonWidth="100%"
                create={item ? 'Save' : 'Add'}
                chancel="Cancel"
                onClose={close}
            />
        </form>
    );
};
