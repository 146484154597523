import React from 'react';
import { useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';
import { CardHeader, cardStyle, getCardBorderTop } from 'components';
import { Colors, Images, PermissionList, RolePermission, useModal } from 'utils';
import { serviceSingleStyles } from './styles';
import { ClientOtherDetails } from '../../clientModals';
import { CardItem } from '../../../../components/card/cardItem';

export const ClientOtherInfo = ({}) => {
    const cardClasses = cardStyle();
    const classes = serviceSingleStyles();
    const borderTop = getCardBorderTop('Other Details');
    const { open } = useModal();
    const data = useSelector((state) => state.client.clientItemInfo);

    const renderType = (type) => {
        switch (type) {
            case 'MALE':
                return 'Male';

            case 'FEMALE':
                return 'Female';

            case 'OTHER':
                return 'Other';

            default:
                return 'Not Set';
        }
    };

    const calculateAge = (birthday) => {
        const birthDate = moment(birthday, "YYYY-MM-DD");
        const today = moment();

        const years = today.diff(birthDate, 'years');
        const months = today.diff(birthDate.add(years, 'years'), 'months');
        const days = today.diff(birthDate, 'days');

        return years ===0 && months === 0 ? `${days}d` : `${years > 0 ? `${years}y` : ''} ${months > 0 ? `${months}m` : ''}`;
    };

    return (
        <div
            className={cardClasses.card}
            style={{ width: '32.5%', maxWidth: '510px' || 210, height: 'auto', minHeight: '430px' }}
        >
            <>
                <div className={cardClasses.headerBorder} style={{ borderTop }} />
                <div className={classes.spaceBetween}>
                    <CardHeader
                        color={Colors.ThemeRed}
                        title={'Other Details'}
                        icon={Images.otherDetailsIcon}
                    />
                    {RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
                        <button onClick={() => open(<ClientOtherDetails info={data} />)} className={classes.addButton}>
                            <img src={Images.edit} alt="icon" />
                        </button>
                    }
                </div>
            </>
            <div>
                {data?.gender &&
                    <CardItem
                        title={'Gender'}
                        value={renderType(data.gender)}
                    />
                }
                <CardItem
                    title={'Date of Birth'}
                    value={
                        data?.birthday
                            ? `${moment.utc(data?.birthday).format('MM/DD/YYYY')}, Age: ${calculateAge(data?.birthday)}`
                            : 'Not Set'
                    }
                />
                <div className={classes.statusWrapper} style={{ marginBottom: '8px' }}>
                    {data?.ethnicity &&
                        <Box className={cardClasses.cardItem}
                             style={{ padding: 0 }}>
                            <div className={cardClasses.cardItemContainerStyle} style={{ alignItems: 'center' }}>
                                <p className={classes.rowTitle} style={{ whiteSpace: 'nowrap' }}>
                                    Ethnicity:
                                </p>
                                <span style={{ width: '100%', marginTop: '0' }} className={cardClasses.cardValue}>
                                {data?.ethnicity}
                            </span>
                            </div>
                        </Box>
                    }
                    <Box className={cardClasses.cardItem} style={{ padding: 0 }}>
                        <div className={cardClasses.cardItemContainerStyle}>
                            <p className={classes.rowTitle} style={{ whiteSpace: 'nowrap' }}>
                                Language:
                            </p>
                            <span style={{ width: '100%', marginTop: '2px' }} className={cardClasses.cardValue}>
                                {data?.languages?.length ? data?.languages?.map((i) => `${i}`).join(', ') : 'Not Set'}
                            </span>
                        </div>
                    </Box>
                    {data?.familyLanguage &&
                        <Box className={cardClasses.cardItem} style={{ padding: 0, marginBottom: 0 }}>
                            <div className={cardClasses.cardItemContainerStyle}>
                                <p className={classes.rowTitle} style={{ whiteSpace: 'nowrap' }}>
                                    Family Language:
                                </p>
                                <span style={{ width: '100%', marginTop: '2px' }} className={cardClasses.cardValue}>
                                {data?.familyLanguage}
                            </span>
                            </div>
                        </Box>
                    }
                </div>
                {data?.registrationDate &&
                    <CardItem
                        title={'Registration Date'}
                        value={data?.registrationDate && moment.utc(data?.registrationDate).format('MM/DD/YYYY')}
                    />
                }
            </div>
        </div>
    );
};