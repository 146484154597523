import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FullTable } from 'components';
import { ACTION_TYPE, authorizationApptBody, authorizationApptHeight } from './constants';
import { clientActions, httpRequestsOnSuccessActions } from 'store';
import { ScheduleDetails } from 'fragments';
import { FindSuccessItem, useModal, useSecondaryModal } from 'utils';

const RENDER_APPOINTMENTS_LIST = 'RENDER_APPOINTMENTS_LIST';

export const AuthorizationAppt = ({ info }) => {
    const dispatch = useDispatch();
    const { openSecondary } = useSecondaryModal();
    const appt = useSelector((state) => state.client.clientsAuthorizationAppt);
    const { close } = useModal();
    const renderAppointments = FindSuccessItem(RENDER_APPOINTMENTS_LIST);

    useEffect(() => {
        if (renderAppointments?.type === RENDER_APPOINTMENTS_LIST) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_APPOINTMENTS_LIST));
            afterSuccess();
        }
    }, [renderAppointments]);

    useEffect(() => {
        getClientAuthAppt();
    }, []);

    const getClientAuthAppt = (loading) => {
        dispatch(clientActions.getClientsAuthorizationsAppt(info?.id, info?.itemStatus, loading));
    };

    const afterSuccess = () => {
        getClientAuthAppt('noLoad');
        dispatch(clientActions.getClientsAuthorizationsServ(info?.authorizationId?._id, 'noLoad'));
    }

    return (
        <div style={{ width: 900 }}>
            <p className="appointments-for">{`Appointments for ${info?.service?.cptCode}`}</p>
            <FullTable
                margin={'0'}
                generateTable={true}
                height={'medium'}
                head={authorizationApptHeight}
                body={authorizationApptBody(info)}
                loadingType={ACTION_TYPE}
                list={appt?.length ? appt : []}
                handleClickButton={(item) => {
                    openSecondary(
                        <ScheduleDetails
                            currentId={item?.id}
                            getAfterSuccess={afterSuccess}
                        />,
                    );
                }}
                noText={'Appointment'}
                activeRowId={''}
            />
        </div>
    );
};