import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PlacesAutocomplete from 'react-places-autocomplete';
import PlaceIcon from '@material-ui/icons/Place';
import { inputsStyle } from './styles';
import { Colors, CountryList, useGlobalStyles } from 'utils';
import axios from 'axios';
import { ValidationInput } from './validationInput';
import { TextField } from '@material-ui/core';
import { ErrMessage } from '../messages';
import { InputMinLoader } from './inputMiniLoader';
import { SelectTypeAutocomplete } from './selectTypeAutocomplete';
import { httpRequestsOnLoadActions } from 'store';

const defaultCountry = 'United States';
const ADDRESS_LOAD = 'ADDRESS_LOAD';
export const AddressInput = ({
                                 selectedAddress,
                                 setCurrentAddress,
                                 oneInput,
                                 placeholder,
                                 disabled,
                                 errMessage,
                                 noBlue,
                                 noSuite,
                                 column,
                                 notRequired,
                             }) => {
    const classes = inputsStyle();
    const globalStyles = useGlobalStyles();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [inputs, setInputs] = useState({
        city: '',
        country: defaultCountry,
        formattedAddress: '',
        lat: '',
        lng: '',
        state: '',
        street: '',
        zip: '',
    });

    const handleSelect = async (value, ev) => {
        dispatch(httpRequestsOnLoadActions.appendLoading(ADDRESS_LOAD))
        setLoading(true);
        await axios.post(`/address`, { address: value }, { auth: true }).then((response) => {
            const params = {
                ...inputs,
            };
            params['formattedAddress'] = response.data.formattedAddress;
            params['lat'] = response.data.lat;
            params['lng'] = response.data.lng;
            params['city'] = response.data.city;
            params['country'] = response.data.country;
            params['state'] = response.data.state;
            params['street'] = response.data.street;
            params['zip'] = response.data.zip;
            if (response.data.unitNumber) params['unitNumber'] = response.data.unitNumber;
            setCurrentAddress(params);
            setInputs(params);
        })
            .finally(() => {
                setLoading(false);
                dispatch(httpRequestsOnLoadActions.removeLoading(ADDRESS_LOAD))
            });
    };

    useEffect(() => {
        if (selectedAddress) {
            setInputs(selectedAddress);
        }
    }, [selectedAddress]);

    const handleChange = (e, name) => {
        const currentName = e?.target?.name ? e.target.name : name;
        const value = name === 'street' ? e : e?.target?.value;
        const params = {
            ...inputs,
        };
        params[currentName] = value;
        setInputs(params);

        let formattedAddress = '';
        if (params?.street) formattedAddress += `${params.street}, `;
        if (params?.city) formattedAddress += ` ${params.city}, `;
        if (params?.state) formattedAddress += ` ${params.state}, `;
        if (params?.zip) formattedAddress += ` ${params.zip}, `;
        if (params?.country) formattedAddress += ` ${params.country}`;
        params['formattedAddress'] = formattedAddress;
        setCurrentAddress(params);
    };

    const addressIsValid = errMessage ? !inputs?.street ? 'street' : !inputs?.city ? 'city' : !inputs?.country ? 'country' : '' : ' ';

    return (
        <div>
            <div className={'flex-column'}>
                <PlacesAutocomplete
                    value={inputs?.street ? inputs?.street : ''}
                    onChange={(e) => handleChange(e, 'street')}
                    onSelect={(ev) => handleSelect(ev)}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loadSuggestions }) => {
                        return (
                            <div className={!oneInput ? globalStyles.addressSimpleInput : globalStyles.simpleInputFull}>
                                <TextField
                                    label={placeholder ? placeholder : `Physical Address${notRequired ? '' : '*'}`}
                                    value={inputs?.street ? inputs?.street : ''}
                                    variant="outlined"
                                    fullWidth
                                    className={
                                        noBlue ? classes.inputTextField :
                                            selectedAddress?.street ? globalStyles.inputTextFieldBlue : classes.inputTextField}
                                    {...getInputProps({
                                        disabled: disabled,
                                    })}
                                    error={addressIsValid === 'street'}
                                    InputProps={{
                                        endAdornment: loading && <InputMinLoader />,
                                    }}
                                />
                                <div style={{ position: 'relative' }}>
                                    <ErrMessage text={addressIsValid === 'street' ? 'Street is required' : ' '} />
                                </div>

                                <div style={{ position: 'relative' }}>
                                    {loadSuggestions && <div>Loading...</div>}

                                    <div className={classes.valuesContainer}>
                                        {suggestions.map((suggestion, index) => {
                                            const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                            const style = { margin: '15px', cursor: 'pointer' };
                                            return (
                                                <div
                                                    key={index}
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <PlaceIcon style={{ color: Colors.ThemeBlue }} />
                                                        <span
                                                            className={classes.searchfAddressDescriptionText}>{suggestion.description}</span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    }}
                </PlacesAutocomplete>

                {!noSuite &&
                    <ValidationInput
                        className={(!noBlue && selectedAddress?.unitNumber) && globalStyles.inputTextFieldBlue}
                        style={globalStyles.addressSimpleInput}
                        variant={'outlined'}
                        name={'unitNumber'}
                        type={'text'}
                        label={'Unit/Suit #'}
                        onChange={handleChange}
                        value={inputs?.unitNumber}
                        disabled={disabled}
                    />
                }

                {!oneInput &&
                    <div className={classes.inputs}>
                        <ValidationInput
                            className={selectedAddress?.city && globalStyles.inputTextFieldBlue}
                            style={globalStyles.addressSimpleInput}
                            variant={'outlined'}
                            name={'city'}
                            type={'name'}
                            label={notRequired ? 'City' : 'City*'}
                            onChange={handleChange}
                            value={inputs?.city}
                            loader={loading}
                            disabled={disabled}
                            typeError={addressIsValid === 'city' ? 'City is required' : ''}
                        />
                        <ValidationInput
                            className={selectedAddress?.state && globalStyles.inputTextFieldBlue}
                            style={globalStyles.addressSimpleInput}
                            variant={'outlined'}
                            name={'state'}
                            type={'name'}
                            label={'State'}
                            onChange={handleChange}
                            value={inputs?.state}
                            loader={loading}
                            disabled={disabled}
                        />
                        <div className={'flex'} style={{ gap: 16 }}>
                            <SelectTypeAutocomplete
                                loadType={ADDRESS_LOAD}
                                style={'full-width'}
                                disabled={disabled}
                                title={notRequired ? 'Country' : 'Country*'}
                                name={'country'}
                                handleSelect={handleChange}
                                defaultValue={inputs?.country}
                                list={CountryList}
                                renderValue={(i) => i?.name}
                                error={addressIsValid}
                                typeError={addressIsValid === 'country' ? 'Country is required' : ''}
                                showErrorAlways={true}
                            />
                            {/*<SelectInput*/}
                            {/*    className={selectedAddress?.country && globalStyles.inputTextFieldBlue}*/}
                            {/*    style={globalStyles.addressSimpleInput}*/}
                            {/*    name={'country'}*/}
                            {/*    label={notRequired ? 'Country' : 'Country*'}*/}
                            {/*    handleSelect={handleChange}*/}
                            {/*    loader={loading}*/}
                            {/*    value={inputs?.country || 'US'}*/}
                            {/*    list={CountryList}*/}
                            {/*    disabled={disabled}*/}
                            {/*    typeError={addressIsValid === 'country' ? 'Country is required' : ''}*/}
                            {/*    showErrorAlways={true}*/}
                            {/*/>*/}

                            <ValidationInput
                                className={selectedAddress?.zip && globalStyles.inputTextFieldBlue}
                                style={globalStyles.simpleInputNoRight}
                                variant={'outlined'}
                                name={'zip'}
                                type={'text'}
                                label={'Zip Code'}
                                onChange={handleChange}
                                value={inputs?.zip}
                                loader={loading}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};