import React from 'react';
import { Colors, Images } from 'utils';
import { MinLoader } from '../loader';

export const EditWithIcon = ({ handleClick, styles, load, icon }) => {
    return (
        <button style={{ ...styles }} className="edit-btn-box" onClick={handleClick}>
            {load ?
                <div>
                    <MinLoader margin={'0'} color={Colors.ThemeBlue} position={'relative'} />
                </div>
                :
                <img src={icon ? icon : Images.edit} alt="" />
            }
        </button>
    );
};