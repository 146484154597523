import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
    FLAGGED_ENUMS,
    renderClientName,
    SaveParams,
} from 'utils';
import { DateTypeSelector, DownloadCsv, SelectTypeAutocomplete, SelectAutocompletePagination } from 'components';
import { fundingSourceActions } from 'store';
import { claimTabEnums } from './constants';

export const ClaimFilters = ({ }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pushInfo = history?.location?.state;
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);

    useEffect(() => {
        dispatch(fundingSourceActions.getFundingSource({sortType: 'name', orderType: 'ASC'}));
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        info.skip = 0;
        info.limit = 50;
        info.page = 1;

        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
            if (e.target.name === 'client') {
                info['clientFull'] = e.target?.full;
            }
        }
        SaveParams(history, { ...info });
    };

    return (
        <div>
            <div className="table-invoice-filter-section">
                <div className="billing-invoices-filter-box">
                    <SelectTypeAutocomplete
                        placeholder={pushInfo?.payer ? '' : 'All'}
                        name={'payer'}
                        handleSelect={handleChange}
                        defaultValue={pushInfo?.payer}
                        list={fundingSourceList?.funders ?
                            [{ id: 'All', name: 'All' }, ...fundingSourceList?.funders]
                            :
                            [{ id: 'All', name: 'All' }]
                        }
                        renderValue={(i) => i?.name}
                        uiType={'tableFilter'}
                        outLabel={'Payer'}
                        noError={true}
                    />
                    <SelectAutocompletePagination
                        outLabel={'Client'}
                        name={'client'}
                        handleSelect={handleChange}
                        placeholder={'All'}
                        defaultValue={pushInfo?.client}
                        renderValue={(i) => renderClientName(i)}
                        type={'client'}
                        uiType={'tableFilter'}
                        noError={true}
                        currentSelected={pushInfo?.clientFull && pushInfo?.clientFull}
                    />
                    {pushInfo?.tabType !== claimTabEnums?.SUBMITTED &&
                        <SelectTypeAutocomplete
                            placeholder={pushInfo?.flag ? '' : 'All'}
                            name={'flag'}
                            handleSelect={handleChange}
                            defaultValue={pushInfo?.flag}
                            list={FLAGGED_ENUMS}
                            renderValue={(i) => i?.label}
                            uiType={'tableFilter'}
                            outLabel={'Flagged'}
                            noError={true}
                        />
                    }
                    <DateTypeSelector
                        startName={'from'}
                        endName={'to'}
                        outLabel={'DOS'}
                    />
                </div>

                <div className="billing-invoices-filter-box">
                    <DownloadCsv
                        alwaysSmall={true}
                        fromModal={true}
                        type={'pendingClaims'}
                    />
                </div>
            </div>
        </div>
    );
};