import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';
import { CreateChancel } from '../buttons';
import { modalsStyle } from './styles';
import { ValidationInput } from '../inputs';
import { FindLoad, FindSuccess, Images } from 'utils';
import { availabilityScheduleActions } from 'store/availabilitySchedule';
import { httpRequestsOnSuccessActions } from 'store';
import { Loader } from '../loader';

const inputStyle = {
    widths: '111px',
};

const ACTION_TYPE = 'CREATE_AVAILABILITY_SCHEDULE_GLOBAL'
const GET_ACTION_TYPE = 'GET_AVAILABILITY_SCHEDULE_GLOBAL'
export const AvailabilitySchedule = ({ onModel }) => {
    const classes = modalsStyle();
    const dispatch = useDispatch();
    const availabilityData = useSelector(state => state.availabilitySchedule.availabilitySchedule)
    const params = useParams();
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const loadData = FindLoad(GET_ACTION_TYPE);
    const [times, setTime] = useState({
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
    });

    useEffect(() => {
        if(availabilityData && Object?.keys(availabilityData).length !== 0){
            setTime(availabilityData)
        }
    }, [availabilityData]);

    const addNewRow = (key) => {
        let newObj = { ...times };
        newObj[key].push({
            from: moment("08:00 AM", "hh:mm A").format("HH:mm"),
            to: moment("06:00 PM", "hh:mm A").format("HH:mm"),
            available: true,
        });
        setTime(newObj);
    };

    const changeData = (e, index, key) => {
        let obj = { ...times };
        obj[key][index][e.target.name] = e.target.value;
        if (e.target.value === '') {
            obj[key][index][e.target.name] = !e.target.checked;
        }
        setTime(obj);
    };

    const removeRow = (key, index) => {
        let obj = { ...times };
        obj[key].splice(index, 1);
        setTime(obj);
    };

    const handleSubmit = () => {
        dispatch(availabilityScheduleActions.createAvailabilitySchedule(times, params.id, onModel));
    };

    useEffect(() => {
        if (!!success.length) {
            // handleClose();
            dispatch(
                httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE),
            );
        }
    }, [success]);

    const handleClose = () => {
            setTime({
                monday: [],
                tuesday: [],
                wednesday: [],
                thursday: [],
                friday: [],
                saturday: [],
                sunday: [],
            });
    }

    if(loadData?.length){
        return  <Loader/>
    }

    return (
        <div>
            <div className={classes.scrollable}>
                {Object?.keys(times)?.map(function(key, weekDayIndex) {
                    return (
                        <div
                            style={times[key]?.length ? {} : {
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                            key={weekDayIndex} className={classes.timeRow}>
                            <p
                                style={{ color: times[key]?.length ? '#0C66E4' : '#535862' }}
                                className={classes.dayName}>{key}</p>

                            <div style={{ display: 'flex' }}>
                                {!times[key]?.length && (
                                    <div
                                        className={classes.addTime}
                                        onClick={() => addNewRow(key)}
                                    >
                                        <img src={Images.addCircleBlue} alt="" />
                                        <span>Add Hours</span>
                                    </div>
                                )}
                            </div>

                            {!!times?.[key]?.length &&
                                <div>
                                    {times?.[key]?.map((item, index) => {
                                        return (
                                            <div key={index} className={classes.times}>
                                                <div className="flex-align-center">
                                                    <ValidationInput
                                                        style={inputStyle}
                                                        className={classes.timeInputStyle}
                                                        errorFalse={true}
                                                        disabled={!item?.available}
                                                        name="from"
                                                        value={item?.from}
                                                        type="time"
                                                        onChange={(e) => {
                                                            changeData(e, index, key);
                                                        }}
                                                    />
                                                    <p className={classes.smallLine}>-</p>
                                                    <ValidationInput
                                                        style={inputStyle}
                                                        className={classes.timeInputStyle}
                                                        errorFalse={true}
                                                        disabled={!item?.available}
                                                        name="to"
                                                        value={item?.to}
                                                        type="time"
                                                        onChange={(e) => {
                                                            changeData(e, index, key);
                                                        }}
                                                    />
                                                    <div style={{marginLeft: 24}}>
                                                        <Checkbox
                                                            checked={!item?.available}
                                                            name="available"
                                                            className={classes.customCheckbox}
                                                            onChange={(e) => {
                                                                changeData(e, index, key);
                                                            }}
                                                        />
                                                        <span className={classes.notAvailableText}>unavailable</span>
                                                    </div>
                                                </div>

                                                <div className="flex-align-center">
                                                    <span className={classes.removeTimeBtn}
                                                        onClick={() => {
                                                            removeRow(key, index);
                                                        }}
                                                    >Remove</span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {times[key]?.length ? (
                                        <button
                                            className={classes.addMoreHoursBtn}
                                            onClick={() => addNewRow(key)}
                                        >
                                            <img src={Images.addBluePlus} alt="icon" />
                                            Add more hours
                                        </button>
                                    ) : null}
                                </div>
                            }
                        </div>
                    );
                })}
            </div>
            <div style={{ padding: '20px 10px 0 10px' }}>
                <CreateChancel
                    loader={!!loader?.length}
                    create={'Save Changes'}
                    chancel={'Cancel'}
                    onCreate={handleSubmit}
                    onClose={handleClose}
                    buttonWidth="100%"
                />
            </div>
        </div>
    );
};
