import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment/moment';
import { scheduleStyle } from './styles';
import {
    CheckboxesTags,
    CustomizedSwitch,
    DateTypeSelector,
    DownloadCsv,
    SelectTypeAutocomplete,
    SimpleTooltip,
    SelectAutocompletePagination
} from 'components';
import {
    APPT_FLAGGED_ENUMS, clientStatusEnums,
    PermissionList,
    renderClientName, renderStaffName,
    RolePermission,
    SaveParams,
    staffStatusEnums, User, Images
} from 'utils';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { scheduleStatusEnums, scheduleTypes } from './constants';

export const Filters = ({ handleOpen, openCloseFullScreen, closeFullScreen, fullView }) => {
    const currentDate = moment();
    const currentWeekStart = currentDate.clone().startOf('isoWeek');
    const currentWeekEnd = currentDate.clone().endOf('isoWeek');
    const classes = scheduleStyle();
    let history = useHistory();
    const info = history?.location?.state;
    const [showFilters, setShowFilters] = useState(true);
    const { userInfo } = User();

    moment.locale('es-es', {
        week: {
            dow: 1,
        },
    });

    const handleChange = (e) => {
        const newFilters = {
            ...info,
        };
        newFilters.page = 1;
        newFilters.skip = 0;

        if (e?.target?.value === 'All') {
            delete newFilters[e.target.name];
        } else {
            newFilters[e.target.name] = e.target.value;
            if (e.target.name === 'staff' || e.target.name === 'client') {
                newFilters[e.target.name === 'staff' ? 'staffFull' : 'clientFull'] = e.target?.full;
            }
        }
        SaveParams(history, { ...newFilters });
    };

    const handleSelectValue = (list, name) => {
        const params = {
            ...info,
        };
        params.page = 1;
        params.skip = 0;
        list?.length ? params[name] = list : delete params[name];
        SaveParams(history, { ...params });
    };

    const handleChangeScreen = (type) => {
        closeFullScreen && closeFullScreen();
        const pushInfo = {
            ...info,
            viewType: type,
        };
        delete pushInfo.screenType;
        SaveParams(history, { ...pushInfo });
    };

    const changeShowActive = () => {
        const pushInfo = {
            ...info,
            showActive: !info?.showActive,
        };
        SaveParams(history, { ...pushInfo });
    };
    const goToNextWeek = () => {
        const date1 = moment(info?.firstDate).format();
        const date2 = moment(info?.lastDate).format();
        const daysDifference = moment(date2).diff(moment(date1), 'days') + 1;
        const updatedFirstDate =
            info?.firstDate ?
                moment(info?.firstDate).add(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().add(1, 'weeks').startOf('isoWeek').format('LLLL');
        const updatedLastDate =
            info?.lastDate ?
                moment(info?.lastDate).add(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().add(1, 'weeks').endOf('isoWeek').format('LLLL');

        history.push({
            state: {
                ...info,
                firstDate: updatedFirstDate,
                lastDate: updatedLastDate,
            },
        });
    };

    const goToLastWeek = () => {
        const date1 = moment(info?.firstDate).format();
        const date2 = moment(info?.lastDate).format();
        const daysDifference = moment(date2).diff(moment(date1), 'days') + 1;
        const updatedFirstDate =
            info?.firstDate ?
                moment(info?.firstDate).subtract(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().subtract(1, 'weeks').startOf('isoWeek').format('LLLL');
        const updatedLastDate =
            info?.lastDate ?
                moment(info?.lastDate).subtract(daysDifference, 'days').format('LLLL') :
                moment(info?.firstDate ? info?.firstDate : currentWeekStart).clone().subtract(1, 'weeks').endOf('isoWeek').format('LLLL');

        history.push({
            state: {
                ...info,
                firstDate: updatedFirstDate,
                lastDate: updatedLastDate,
            },
        });
    };


    // const currentWeek =
    //     `${moment(info?.firstDate ? info?.firstDate : currentWeekStart).format('MMMM D')}
    //    –
    //      ${moment(info?.lastDate ? info?.lastDate : currentWeekEnd).format('MMMM D')}`;
    //
    const renderTabBtns = () => {
        return (
            <div className={classes.scheduleViewBtnsBox}>
                <button
                    className={(info?.viewType === 'list' || !info?.viewType) ? classes.scheduleViewActiveBtn : classes.scheduleViewPassiveBtn}
                    onClick={() => handleChangeScreen('list')}
                >
                    <img src={Images.scheduleList} alt="icon" />
                </button>

                <button
                    className={info?.viewType === 'calendar' ? classes.scheduleViewActiveBtn : classes.scheduleViewPassiveBtn}
                    onClick={() => handleChangeScreen('calendar')}
                >
                    <img src={Images.scheduleGroup} alt="icon" />
                </button>
            </div>
        );
    };

    const createEventBtn = () => {
        return (
            <div className="export-csv-box">
                <SimpleTooltip
                    title={
                        <p className={classes.infoTextForAuthStyle}>
                            {!info?.showActive ? 'All clients and staff, including inactive ones, will be available to select.' : 'Only active clients and staff will be available to select.'}
                        </p>
                    }
                    placement="top-end"
                >
                    <div className="status-switch-wrapper">
                        <p>{'Show Active'}</p>
                        <CustomizedSwitch
                            handleClick={changeShowActive}
                            checked={!info?.showActive}
                        />
                    </div>
                </SimpleTooltip>

                {RolePermission([PermissionList.REPORT_MANAGE?.code]) &&
                    <DownloadCsv type={'schedule'} fromModal={true} smallSize={true} />
                }
                {RolePermission([
                        PermissionList.APPT_SERVICE_CREATE?.code,
                        PermissionList.APPT_CONNECTED_CREATE?.code,
                        PermissionList.APPT_DRIVE_CREATE?.code,
                        PermissionList.APPT_BREAK_CREATE?.code,
                        PermissionList.APPT_PTO_CREATE?.code,
                        PermissionList.APPT_STAFF_CREATE?.code,
                        PermissionList.APPT_SELF_CREATE?.code,
                        PermissionList.APPT_SICK_TIME_CREATE?.code,
                    ]) && userInfo?.status === staffStatusEnums?.ACTIVE &&
                    <div onClick={handleOpen} className={classes.addEvent}>
                        <img src={Images.addCircle} alt="icon" />
                        <p>Add Appointment</p>
                    </div>
                }
            </div>
        );
    };

    return (
        <div className="schedule-filters">
            <div className="schedule-filter-wrapper">
                <div className="schedule-filter-box">
                    {!fullView &&
                        <button className={`filter-passive-btn ${showFilters ? 'active-filter' : ''}`}
                                onClick={() => setShowFilters(!showFilters)}>
                            <img src={Images.filters} alt="icon" />
                            <p>Filters</p>
                        </button>
                    }
                    <div>
                        {renderTabBtns()}
                    </div>

                    {info?.viewType === 'calendar' &&
                        <button className="full-view" onClick={openCloseFullScreen}>
                            <img src={fullView ? Images.minimize : Images.expandFull} alt="icon" />
                        </button>
                    }

                    <div className={classes.calendarNextPrewButtons}>
                        <div className={classes.buttonsWrapper}>
                            <div className={classes.navigationButtons}>
                                <NavigateBefore
                                    style={{ color: '#387DFF', cursor: 'pointer' }}
                                    onClick={() => goToLastWeek()}
                                />
                                <NavigateNext
                                    style={{ color: '#387DFF', cursor: 'pointer' }}
                                    onClick={() => goToNextWeek()}
                                />
                            </div>
                            <div className={classes.dateStyle}>
                                <span>{
                                    `${moment(info?.firstDate ? info?.firstDate : currentWeekStart).format('MMMM D')}
                                    –
                                     ${moment(info?.lastDate ? info?.lastDate : currentWeekEnd).format('MMMM D')}`
                                }</span>
                            </div>
                        </div>
                    </div>
                </div>
                <>
                    {createEventBtn()}
                </>
            </div>

            <div className={info?.viewType !== 'calendar' ? 'calendar-filters-view' : ''}>
                {showFilters && !fullView &&
                    <div className={classes.filtersWrapper}>
                        <div className={classes.filtersWrapperRow}>
                            <div>
                                <SelectAutocompletePagination
                                    defaultStatuses={!info?.showActive ? [staffStatusEnums.ACTIVE] : null}
                                    outLabel={'Staff Member'}
                                    name={'staff'}
                                    handleSelect={handleChange}
                                    placeholder={'All'}
                                    defaultValue={info?.staff}
                                    renderValue={(i) => renderStaffName(i)}
                                    type={'staff'}
                                    uiType={'tableFilter'}
                                    noError={true}
                                    currentSelected={info?.staffFull && info?.staffFull}
                                />
                            </div>
                            <div>
                                <SelectAutocompletePagination
                                    defaultStatuses={!info?.showActive ? [clientStatusEnums.ACTIVE, clientStatusEnums.FBA, clientStatusEnums.ON_HOLD, clientStatusEnums.WAIT_LIST] : null}
                                    outLabel={'Client'}
                                    name={'client'}
                                    handleSelect={handleChange}
                                    placeholder={'All'}
                                    defaultValue={info?.client}
                                    renderValue={(i) => renderClientName(i)}
                                    type={'client'}
                                    uiType={'tableFilter'}
                                    noError={true}
                                    currentSelected={info?.clientFull && info?.clientFull}
                                />
                            </div>
                            <CheckboxesTags
                                handleChange={(e) => handleSelectValue(e, 'types')}
                                name="types"
                                renderValue={(i) => i?.name}
                                value={info?.types ? info?.types : []}
                                permissionsList={scheduleTypes}
                                outLabel={'Event Type'}
                                placeholder={info?.types?.length ? info?.types?.map((i) => i?.name).join(', ') : 'All'}
                                uiType={'tableFilter'}
                                notShowError={true}
                                styles={{ width: 'auto' }}
                            />
                            <CheckboxesTags
                                handleChange={(e) => handleSelectValue(e, 'statuses')}
                                name="statuses"
                                renderValue={(i) => i?.name}
                                value={info?.statuses ? info?.statuses : []}
                                permissionsList={scheduleStatusEnums}
                                outLabel={'Event Status'}
                                placeholder={info?.statuses?.length ? info?.statuses?.map((i) => i?.name).join(', ') : 'All'}
                                uiType={'tableFilter'}
                                notShowError={true}
                                styles={{ width: 'auto' }}
                            />
                            <div>
                                <p className={classes.label}>
                                    Date of Service
                                </p>
                                <div className={classes.dosInputsBox}>
                                    <DateTypeSelector
                                        startName={'firstDate'}
                                        endName={'lastDate'}
                                        reset={true}
                                    />
                                </div>
                            </div>

                            <div>
                                <SelectTypeAutocomplete
                                    outLabel={'Flagged'}
                                    placeholder={info?.flag ? '' : 'All'}
                                    name={'flag'}
                                    handleSelect={handleChange}
                                    defaultValue={info?.flag}
                                    list={APPT_FLAGGED_ENUMS}
                                    renderValue={(i) => i?.label}
                                    uiType={'tableFilter'}
                                    noError={true}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};