import moment from 'moment/moment';
import {
    formatAMPMNeW,
    Images,
    PermissionList,
    renderClientName,
    renderStaffName,
    RolePermission,
} from '../../../../../utils';
import React from 'react';
import { billStatuses, SERVICE } from '../../../constants';
import { SimpleTooltip, TextRow } from '../../../../../components';
import toolInfoIcon from '../../../../../assets/images/icons/toolInfoIcon.svg';


export const apptDetailStatus = (status, item, fromAppt, editReason) => {

    switch (status) {
        case 'CANCELLED':
            return <div className="flex-align-center" style={{ gap: '8px' }}>
                <div className="appt-status-card appt-cancelled">
                    <div />
                    Cancelled
                </div>
                {item?.cancellation?._id && !fromAppt &&
                    <div className="canceled-reason-wrapper">
                        <div className="canceled-reason">
                            {item?.cancellation?.reason ?
                                <SimpleTooltip title={<p>{item?.cancellation?.reason}</p>} placement="top" arrow>
                                    <p>Reason: {item?.cancellation?.reason?.length > 40 ? `${item?.cancellation?.reason.slice(0, 40)}...` : item?.cancellation?.reason}</p>
                                </SimpleTooltip>
                                :
                                <p>
                                    {`Reason: ${reasonEnumsConvert[item?.cancellation?.type]}`}
                                </p>
                            }
                            <button onClick={editReason} className="reset-btn-no-paddings">
                                <img src={Images.noteEdit} alt="icon"
                                     style={{ filter: 'invert(24%) sepia(96%) saturate(3084%) hue-rotate(209deg) brightness(95%) contrast(91%)' }} />
                            </button>
                        </div>
                    </div>

                }
            </div>;
        case 'PENDING':
            return <div
                className={`appt-status-card ${item?.type === SERVICE ? 'service-appt-pending' : 'appt-pending'}`}>
                <div />
                Pending
            </div>;
        case 'COMPLETED':
            return item?.type === SERVICE ?
                fromAppt ?
                    <div>
                        <div className="appt-status-card service-appt-completed">
                            <div />
                            Completed
                        </div>
                        <p className="appt-bill-status">{item?.billStatus === billStatuses.NOT_BILLED ? `(Not Billed)` : item?.billStatus === billStatuses.BILLED ? `(Billed)` : ''}</p>
                    </div>
                    :
                    <div className="appt-status-card service-appt-completed">
                        <div />
                        {`Completed ${item?.billStatus === billStatuses.NOT_BILLED ? `(Not Billed)` : item?.billStatus === billStatuses.BILLED ? `(Billed)` : ''}`}
                    </div>
                :
                <div className="appt-status-card appt-completed">
                    <div />
                    Completed
                </div>;
        default:
            return { color: '', text: '' };
    }
};

export const serviceApptDetailList = (item) => [
    { title: 'Appt ID:', value: item?.displayId },
    {
        title: 'Date/Time:', value: <div className="flex-align-center" style={{ gap: '8px' }}>
            <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
            <p className="schedule-time">
                {`${formatAMPMNeW(item?.startTime)} - ${formatAMPMNeW(item?.endTime)}`}
            </p>
        </div>,
    },
    {
        title: 'Staff:', value: item?.staff ? renderStaffName(item?.staff) : null,
    },
    {
        title: 'Client:',
        value: item?.client ? renderClientName(item?.client) : null,
    },
    {
        title: 'Service:', value: item?.authorizedService?.service?.cptCode,
    },
];
export const apptDetailList = (item) => [
    { title: 'Appt ID:', value: item?.displayId },
    {
        title: 'Date/Time:', value: <div className="flex-align-center" style={{ gap: '8px' }}>
            <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
            <p className="schedule-time">
                {`${formatAMPMNeW(item?.startTime)} - ${formatAMPMNeW(item?.endTime)}`}
            </p>
        </div>,
    },
];

export const apptDetailTabsLabels = [
    { label: 'Details' },
    { label: 'Links' },
    { label: 'Notes' },
    RolePermission([PermissionList.APPT_FILE_READ?.code, PermissionList.APPT_FILE_MANAGE?.code]) &&
    { label: 'Files' },
    RolePermission([PermissionList.APPT_HISTORY_READ?.code]) &&
    { label: 'History' },
];

export const linksNotYet = {
    title: 'No Links Yet',
    // subTitle: 'Upload files to store relevant documents here.',
    image: Images.notFile,
    height: '200px',
};

export const reasonEnums = {
    STAFF_CANCELLATION: 'STAFF_CANCELLATION',
    CLIENT_CANCELLATION: 'CLIENT_CANCELLATION',
    CLIENT_LATE: 'CLIENT_LATE',
    OTHER: 'OTHER',
};

export const reasonEnumsConvert = {
    STAFF_CANCELLATION: 'Staff Cancellation',
    CLIENT_CANCELLATION: 'Client Cancellation',
    CLIENT_LATE: 'Client Late',
    OTHER: 'Other',
};

export const reasonList = [
    { title: 'Staff Cancellation', value: reasonEnums.STAFF_CANCELLATION },
    { title: 'Client Cancellation', value: reasonEnums.CLIENT_CANCELLATION },
    { title: 'Client Late', value: reasonEnums.CLIENT_LATE },
    { title: 'Other', value: reasonEnums.OTHER },
];