import React from 'react';
import { DateRow, LinkRow, TextRow } from 'components';
import { formatAMPMNeW, Images, PermissionList, RolePermission } from '../../../../../utils';
import { UnitsRowByHour, UnitsRowByHourOneLine } from '../../../../../components/table/tableRows';

export const authorizationApptHeight = [
    { name: '', title: 'Service Date', custom: false, noSearch: true, width: '130px' },
    { name: '', title: 'Service Time', custom: false, noSearch: true, width: '130px' },
    { name: '', title: 'Client', custom: false, noSearch: true },
    { name: '', title: 'Staff ', custom: false, noSearch: true },
    { name: '', title: 'Units Completed', custom: false, noSearch: true },
    { name: '', title: ' ', custom: false, noSearch: true, width: '50px' },
];

export const authorizationApptBody = (authInfo) => [
    { rowText: (item) => <DateRow date={item?.startDate} /> },
    { rowText: (item) => <p>  {`${formatAMPMNeW(item?.startTime)}-${formatAMPMNeW(item?.endTime)}`}</p> },
    {
        rowText: (item) => item?.client?.fullName ?
            <LinkRow
                name={item?.client?.fullName}
                href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.client?.id}` : ''}
                checkPermission={item?.client?.id ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                textWidth={8}
            />
            :
            'N/A',
    },
    {
        rowText: (item) =>
            <LinkRow
                name={item?.staff?.fullName}
                href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staff?.id}` : ''}
                checkPermission={item?.staff?.id ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                textWidth={8}
            />,
    },
    {
        rowText: (item) =>
            <UnitsRowByHourOneLine
                info={item?.units}
                size={authInfo?.service?.unitSize}
                textWidth={10}
            />,
    },
    {
        button: (cb, item) => <button onClick={() => cb(item)} className="reset-btn-by-default">
            <img src={Images.show} alt="" />
        </button>,
    },
];

export const ACTION_TYPE = 'GET_AUTH_UNIT_INFO';

export const unitStatusesEnum = {
    COMPLETED: 'COMPLETED',
    PENDING: 'PENDING',
    CANCELLED: 'CANCELLED',
};