import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DateRangePicker } from 'react-date-range';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    APPT_FLAGGED_ENUMS,
    clientStatusEnums,
    Images,
    renderClientName,
    renderStaffName,
    SaveParams,
    staffStatusEnums,
} from 'utils';
import { CheckboxesTags, SelectTypeAutocomplete, SelectAutocompletePagination } from 'components';
import { scheduleStatusEnums, scheduleTypes } from '../constants';
import { navBarStyles } from '../../../menuBar/core/style';

export const CalendarFilters = ({}) => {
    const { basicAdmins, basicClients, company } = useSelector((state) => ({
        company: state.system.company,
        basicAdmins: state.admins.basicAdmins,
        basicClients: state.client.basicClients,
    }));
    const classes = navBarStyles();
    let history = useHistory();
    const info = history?.location?.state;
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (info?.firstDate && info?.lastDate) {
            setState([{
                startDate: moment(info?.firstDate).format(),
                endDate: new Date(info?.lastDate),
                key: 'selection',
            }]);
        }
    }, [info]);
    const handleDateChange = (item) => {
        setState([item.selection]);

        const params = {
            ...info,
        };
        params.firstDate = item?.selection?.startDate;
        params.lastDate = item?.selection?.endDate;
        SaveParams(history, params);
    };

    const handleChange = (e) => {
        const newFilters = {
            ...info,
        };
        if (e.target.value === 'All') {
            delete newFilters[e.target.name];
        } else {
            newFilters[e.target.name] = e.target.value;
            if (e.target.name === 'staff' || e.target.name === 'client') {
                newFilters[e.target.name === 'staff' ? 'staffFull' : 'clientFull'] = e.target?.full;
                // newFilters[e.target.name === 'staff' ? 'staffFullName' : 'clientFullName'] = e.target?.fullName;
            }
        }
        SaveParams(history, { ...newFilters });
    };

    const handleSelectValue = (list, name) => {
        const params = {
            ...info,
        };
        params.page = 1;
        params.skip = 0;
        list?.length ? params[name] = list : delete params[name];
        SaveParams(history, { ...params });
    };

    return (
        <div className="calendar-filters">
            <div className="calendar-filters-wrapper">
                <div className="company-information">
                    {company?.name &&
                        <div className="company-information-box">
                            <div className={classes.companyInfoWrapper}>
                                <div className={company?.logo?.url ? classes.companyLogo : classes.companyBox}>
                                    <img src={company?.logo?.url ? company?.logo?.url : Images.office} alt="icon" />
                                </div>
                                <p>{company?.name ? company?.name : ''}</p>
                            </div>
                        </div>
                    }
                </div>
                <DateRangePicker
                    showDateDisplay={false}
                    ranges={state}
                    onChange={handleDateChange}
                    showMonthAndYearPickers={false}
                    showSelectionPreview={false}
                    weekdayDisplayFormat={'EEEEE'}
                />
                <div className="filters-wrapper-row">
                    <div>
                        <SelectAutocompletePagination
                            defaultStatuses={!info?.showActive ? [staffStatusEnums.ACTIVE] : null}
                            outLabel={'Staff Member'}
                            name={'staff'}
                            handleSelect={handleChange}
                            placeholder={'All'}
                            defaultValue={info?.staff}
                            renderValue={(i) => renderStaffName(i)}
                            type={'staff'}
                            uiType={'tableFilter'}
                            currentSelected={info?.staffFull && info?.staffFull}
                        />
                    </div>
                    <div>
                        <SelectAutocompletePagination
                            defaultStatuses={!info?.showActive ? [clientStatusEnums.ACTIVE, clientStatusEnums.FBA, clientStatusEnums.ON_HOLD, clientStatusEnums.WAIT_LIST] : null}
                            outLabel={'Client'}
                            name={'client'}
                            handleSelect={handleChange}
                            placeholder={info?.clientFullName ? info?.clientFullName : 'All'}
                            defaultValue={info?.client}
                            renderValue={(i) => renderClientName(i)}
                            type={'client'}
                            uiType={'tableFilter'}
                            currentSelected={info?.clientFull && info?.clientFull}
                        />
                    </div>
                    <CheckboxesTags
                        handleChange={(e) => handleSelectValue(e, 'types')}
                        name="types"
                        renderValue={(i) => i?.name}
                        value={info?.types ? info?.types : []}
                        permissionsList={scheduleTypes}
                        outLabel={'Event Type'}
                        placeholder={info?.types?.length ? info?.types?.map((i) => i?.name).join(', ') : 'All'}
                        uiType={'tableFilter'}
                        notShowError={true}
                        styles={{ width: 'auto', marginBottom: '20px' }}
                    />
                    <CheckboxesTags
                        handleChange={(e) => handleSelectValue(e, 'statuses')}
                        name="statuses"
                        renderValue={(i) => i?.name}
                        value={info?.statuses ? info?.statuses : []}
                        permissionsList={scheduleStatusEnums}
                        outLabel={'Event Status'}
                        placeholder={info?.statuses?.length ? info?.statuses?.map((i) => i?.name).join(', ') : 'All'}
                        uiType={'tableFilter'}
                        notShowError={true}
                        styles={{ width: 'auto', marginBottom: '20px' }}
                    />
                    <div>
                        <p className="select-label">
                            Flagged
                        </p>
                        <SelectTypeAutocomplete
                            placeholder={info?.flag ? '' : 'All'}
                            name={'flag'}
                            handleSelect={handleChange}
                            defaultValue={info?.flag}
                            list={APPT_FLAGGED_ENUMS}
                            renderValue={(i) => i?.label}
                            uiType={'tableFilter'}
                            noError={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};