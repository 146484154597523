import axios from 'axios';

export const authService = {
    getClientsService: ({ data }) => {
        return axios.get('/client', { auth: true, params: { ...data } });
    },

    getBasicClientsService: (params) => axios.get('/client/basic/info', {
        auth: true,
        params: {
            ...params,
            sortType: params?.sortType ? params?.sortType : 'code',
            orderType: params?.orderType ? params.orderType : 'ASC',
        },
    }),

    createClientService: ({ payload }) =>
        axios.post(`/client`, payload.body, { auth: true }),

    deleteClientService: ({ payload }) =>
        axios.delete(`/client/${payload.id}`, { auth: true }),

    editClientService: ({ payload }) =>
        axios.patch(`/client/${payload.id}`, payload.body, { auth: true }),

    getClientByIdService: ({ payload }) =>
        axios.get(`/client/${payload.id}`, { auth: true }),

    getClientContactsService: ({ payload }) =>
        axios.get(`/contact/client/${payload.id}`, {
            auth: true,
            params: { ...payload?.data },
        }),

    createClientContactService: ({ payload }) =>
        axios.post(`/contact/client/${payload.id}`, payload.body, { auth: true }),

    editClientContactService: ({ payload }) =>
        axios.patch(`/contact/${payload.id}`, payload.body, { auth: true }),

    deleteClientContactService: ({ payload }) =>
        axios.delete(`/contact/${payload.id}`, { auth: true }),

    /** Client Statuses */

    setClientStatus: (id, body) => axios.patch(`/client/${id}/status`, body, { auth: true }),

    /** End */

    /** Client Enrollment */
    getClientEnrollmentService: ({ payload }) => axios.get(`/enrollment/client/${payload.id}`, { auth: true }),

    createClientEnrollmentService: ({ payload }) => axios.post(`/enrollment/client/${payload.id}/funder/${payload.funderId}`, payload.body, { auth: true }),

    editClientEnrollmentService: ({ payload }) => axios.patch(`/enrollment/${payload.id}`, payload.body, { auth: true }),

    terminateClientEnrollmentService: (id, body) => axios.patch(`/enrollment/${id}/terminate`, { ...body }, { auth: true }),

    deleteClientEnrollmentService: ({ payload }) => axios.delete(`/enrollment/${payload.id}`, { auth: true }),

    changeEnrollmentTypeService: (payload) => axios.patch(`/enrollment/${payload.enrolmentId}/client/${payload?.clientId}/primary`, null, {
        auth: true,
        params: { type: payload?.type },
    }),
    /** End */

    /** Client Authorization */
    getClientAuthorizationService: (id, params) => axios.get(`/enrollment/authorizations/client/${id}`, {
        auth: true,
        params: { canUsed: false },
    }),

    createClientAuthorizationService: ({ payload }) => axios.post(`/enrollment/authorizations/client/${payload.id}`, payload.body, { auth: true }),

    editClientAuthorizationService: ({ payload }) => axios.patch(`/enrollment/authorizations/${payload.id}`, payload.body, { auth: true }),

    deleteClientAuthorizationService: ({ payload }) => axios.delete(`/auth/${payload.id}`, { auth: true }),
    /** End */

    // Authorization

    getClientAuthorizationServService: ({ payload }) => axios.get(`/authService/authorizations/${payload.id}`, { auth: true }),

    getClientsAuthorizationApptService: (authId, status) => axios.get(`/appt/authorizedService/${authId}`, {
        auth: true,
        params: { apptStatus: status },
    }),

    createClientAuthorizationServService: ({ payload }) => axios.post(`/authService/authorizations/${payload.id}/fundingService/${payload.funderId}`, payload.body, { auth: true }),

    getClientAuthorizationServCheckModService: ({ payload }) => axios.post(`/authService/authorizations/${payload.id}/fundingService/${payload.funderId}`, payload.body, { auth: true }),

    // End

    // Authorization file

    editClientAuthorizationFileService: ({ payload }) =>
        axios.patch(`/files/${payload.id}`, payload.body, { auth: true }),

    deleteClientAuthorizationFileService: ({ payload }) =>
        axios.delete(`/files/${payload.id}`, { auth: true }),

    getClientAuthorizationFileService: ({ payload }) =>
        axios.get(`/files/${payload.id}`, { auth: true }),

    createClientAuthorizationFileService: ({ payload }) =>
        axios.post(`/files/upload`, payload.body, { auth: true }),

    createClientAuthorizationRestFileService: ({ payload }) =>
        axios.post(`/files`, payload.body, { auth: true }),

    // end


    editClientAuthorizationServService: ({ payload }) => axios.patch(`/authService/${payload?.id}/authorizations/${payload?.authID}`, payload.body, { auth: true }),

    deleteClientAuthorizationServService: ({ payload }) => axios.patch(`/authService/inactivate/${payload.id}`, null, {
        auth: true,
        params: { reason: payload.reason },
    }),


    /** Client Authorization Documents */

    addFilesToClientAuthService: (authId, files) =>
        axios.post(`/enrollment/authorizations/${authId}/documents`, files, {
            auth: true,
        }),

    removeFilesFromClientAuthService: (authId, docId) =>
        axios.delete(`/enrollment/authorizations/${authId}/documents/${docId}`, {
            auth: true,
        }),

    editFileNameOfClientAuthService: (authId, docId, fileName) =>
        axios.patch(`/enrollment/authorizations/${authId}/documents/${docId}/?name=${fileName}`, null, {
            auth: true,
        }),


    /** Client Documents */

    uploadService: (file, id) => axios.post(`/client/${id}/documents`, { ...file }, { auth: true }),

    editService: ({
                      id,
                      dockId,
                      file,
                  }) => axios.patch(`/client/${id}/documents/${dockId}`, { ...file }, { auth: true }),

    getFilesService: (id) => axios.get(`/client/${id}/documents`, { auth: true }),

    deleteFilesService: (clientId, id) => axios.delete(`/client/${clientId}/documents/${id}`, { auth: true }),

    /** End */

    /** Staff Documents */

    addClientAddressService: ({ id, body }) => axios.post(`/client/${id}/address`, { ...body }, { auth: true }),

    editClientAddressService: ({
                                   id,
                                   addressId,
                                   body,
                               }) => axios.patch(`/client/${id}/address/${addressId}`, { ...body }, { auth: true }),

    deleteClientAddressService: ({
                                     id,
                                     addressId,
                                 }) => axios.delete(`/client/${id}/address/${addressId}`, { auth: true }),

    /** End */

    /** Staff Assignment */

    assignStaffService: (body) => axios.post('/clients/assigned-staff', body, { auth: true }),

    editAssignedStaffService: (id, body) => axios.patch(`/clients/assigned-staff/${id}`, body, { auth: true }),

    getAssignedStaffService: (id) => axios.get(`/clients/assigned-staff/byClient/${id}`, { auth: true }),

    removeClientStaffService: (id) => axios.delete(`/clients/assigned-staff/${id}`, { auth: true }),

    /** End */
};
