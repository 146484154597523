import React, { Fragment } from 'react';
import { makeCapitalize } from 'utils';
import { UnitsRow, DetailItem, DateRow } from 'components';
import moment from 'moment/moment';

export const ServiceItems = ({ item }) => {

    const serviceAppt = [
        {
            title: 'Authorization #',
            value: `${item?.authorizedService?.authorization?.authId},
             ${moment.utc(item?.authorizedService?.authorization?.startDate).format('MM/DD/YYYY')} -  
             ${moment.utc(item?.authorizedService?.authorization?.endDate).format('MM/DD/YYYY')} 
             `,
        },
        // { title: 'Authorization #', value: item?.authorizedService?.authorization?.authId },
        {
            title: 'Staff Paycode',
            value: item?.payCodeTypeName ? `${item.payCodeTypeName} ${item?.payCodeTypeCode ? `(${item?.payCodeTypeCode})` : ''}` : null,
        },
        { title: 'Funding Source', value: item?.payerName },
        { title: 'Charge Rate', value: item?.chargeRateName ? item.chargeRateName : 'N/A' },
        {
            title: 'Units',
            value: item?.units ? <UnitsRow info={item?.units} /> : 'N/A',
            backgroundColor: item?.incompleteUnitFlag ? 'rgb(252, 235, 231)' : 'rgba(242, 244, 248, 0.5)',
        },
        {
            title: 'Last Modified Date',
            value: item?.editedDate ? <DateRow date={item?.editedDate} /> : 'N/A',
        },
        // { title: 'Hours', value: item?.hours ? item.hours : 'N/A' },
        { title: 'Hours', value: item?.hours ? item.hours?.toFixed(2) : 'N/A' },
        { title: 'Last Modified By', value: item?.editorFullName ? item.editorFullName : 'N/A' },
        {
            title: 'Place of Service',
            value: `${item?.posName} ${item?.posModifier ? `(${item?.posModifier})` : ''}`,
        },
        { title: 'Created Date', value: item?.createdDate ? <DateRow date={item?.createdDate} /> : 'N/A' },
        {
            title: 'Location',
            value: item?.telehealth ? 'Telehealth' : item?.location?.formattedAddress ? item?.location?.formattedAddress : 'N/A',
        },
        { title: 'Created By', value: item?.creatorFullName ? item.creatorFullName : 'N/A' },
    ];

    return (
        <div className="appt-detail-card-items">
            {serviceAppt.map((i, j) => (
                <Fragment key={j}>
                    <DetailItem
                        background={i?.backgroundColor}
                        title={i?.title}
                        value={i?.value}
                    />
                </Fragment>
            ))}
        </div>
    );
};