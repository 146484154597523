import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment/moment';
import { DownloadFile, Images, useModal } from 'utils';
import { CustomDelete, MinLoader, TextRow } from 'components';
import { appointmentActions } from 'store';
import { signatureTypeEnums } from '../constants';

const DELETE_ACTION_TYPE = 'DELETE_SIGNATURE'
export const SignatureDetails = ({ item }) => {
    const { loadDownload, handleDownload, currentId } = DownloadFile();
    const { open } = useModal();
    const dispatch = useDispatch();

    const handleRemove = (signature, type) => {
        open(<CustomDelete
            text={`Are you sure you want to delete this signature?`}
            info={`Delete ${type === signatureTypeEnums.SIGNATURE ? 'Client' : 'Provider'} Signature?`}
            handleDel={() => dispatch(
                appointmentActions.deleteSignature(item?.id, type),
            )}
            handleClose={() => close()}
            actionType={DELETE_ACTION_TYPE}
            body={
                <div className="deleting-client-info-box">
                    <img src={Images.signatureRemove} alt={'credential'}/>
                    <p>{signature?.originalName}</p>
                </div>
            }
        />);
    }

    return (
        <>
            <div className="signature-process">
                <p className="schedule-details-title">Signature Details</p>

                {item?.providerSignature?._id &&
                    <div className="flex-align-center" style={{ gap: 8 }}>
                        <p className="signature-title">Provider:</p>
                        <div className="flex-align-center full-width" style={{ gap: 8 }}>
                            <div className="flex-align-center" style={{ gap: 8 }}>
                                <div className="signature-detail-box" style={{ maxWidth: '100%' }}>
                                    <div className="flex-align-center" style={{ gap: 4 }}>
                                        <img src={Images.signatureFile} alt="icon" />
                                        <p className="signature-detail-box-title">File:</p>
                                        <p className="signature-detail-name">
                                            <TextRow name={item?.providerSignature?.originalName} textWidth={8} />
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-align-center" style={{ gap: 8 }}>
                                    <button
                                        onClick={() => window.open(item?.providerSignature?.url, '_blank')}
                                        className="square-btn-box"
                                    >
                                        <img height={20} src={Images.showEye} alt="icon" />
                                    </button>
                                    <button
                                        onClick={() => handleDownload(item?.providerSignature, item?.providerSignature?._id)}
                                        className="square-btn-box"
                                    >
                                        {loadDownload && currentId === item?.providerSignature?._id ?
                                            <MinLoader small={true} color={'#475467'} margin={'0'}
                                                       position={'relative'} />
                                            :
                                            <img height={20} src={Images.downloadCloud} alt="icon" />
                                        }
                                    </button>
                                    <button
                                        onClick={() => handleRemove(item?.signature, signatureTypeEnums.PROVIDER_SIGNATURE)}
                                        className="square-btn-box"
                                    >
                                        <img height={20} src={Images.redTrash} alt="icon" />
                                    </button>
                                </div>
                            </div>

                            <div className="signature-detail-box" style={{ maxWidth: '30%' }}>
                                <div className="flex-align-center" style={{ gap: 4 }}>
                                    <img src={Images.signatureDate} alt="icon" />
                                    <p className="signature-detail-box-title">Date/Time:</p>
                                </div>
                                <p className="signature-detail-box-value">
                                    {`${moment.utc(item?.providerSignatureDate).format('MM/DD/YYYY')}, ${moment(item?.providerSignatureDate).format('h:mm a')}`}
                                </p>
                            </div>

                            <div className="signature-detail-box" style={{ width: '32%' }}>
                                <div className="flex-align-center" style={{ gap: 4 }}>
                                    <img src={Images.signatureLocation} alt="icon" />
                                    <p className="signature-detail-box-title">Location:</p>
                                </div>
                                <p className="signature-detail-box-value full-width">
                                    <TextRow name={item?.providerSignatureLocation?.formattedAddress ? item?.providerSignatureLocation?.formattedAddress : 'Not Set'} />
                                </p>
                            </div>
                        </div>
                    </div>
                }

                {(item?.signature?._id && item?.providerSignature?._id) &&
                    <div className="signature-border-line" />
                }

                {item?.signature?._id &&
                    <div className="flex-align-center" style={{ gap: 8 }}>
                        <p className="signature-title">Client:</p>
                        <div className="flex-align-center full-width" style={{ gap: 8 }}>
                            <div className="flex-align-center" style={{ gap: 8 }}>
                                <div className="signature-detail-box" style={{ maxWidth: '100%' }}>
                                    <div className="flex-align-center" style={{ gap: 4 }}>
                                        <img src={Images.signatureFile} alt="icon" />
                                        <p className="signature-detail-box-title">File:</p>
                                        <p className="signature-detail-name">
                                            <TextRow name={item?.signature?.originalName} textWidth={8} />
                                        </p>
                                    </div>
                                </div>
                                <div className="flex-align-center" style={{ gap: 8 }}>
                                    <button
                                        onClick={() => window.open(item?.signature?.url, '_blank')}
                                        className="square-btn-box"
                                    >
                                        <img height={20} src={Images.showEye} alt="icon" />
                                    </button>
                                    <button
                                        onClick={() => handleDownload(item?.signature, item?.signature?._id)}
                                        className="square-btn-box"
                                    >
                                        {loadDownload && currentId === item?.signature?._id ?
                                            <MinLoader small={true} color={'#475467'} margin={'0'}
                                                       position={'relative'} />
                                            :
                                            <img height={20} src={Images.downloadCloud} alt="icon" />
                                        }
                                    </button>
                                    <button
                                        onClick={() => handleRemove(item?.signature, signatureTypeEnums.SIGNATURE)}
                                        className="square-btn-box"
                                    >
                                        <img height={20} src={Images.redTrash} alt="icon" />
                                    </button>

                                </div>
                            </div>

                            <div className="signature-detail-box" style={{ maxWidth: '30%' }}>
                                <div className="flex-align-center" style={{ gap: 4 }}>
                                    <img src={Images.signatureDate} alt="icon" />
                                    <p className="signature-detail-box-title">Date/Time:</p>
                                </div>
                                <p className="signature-detail-box-value">
                                    {`${moment.utc(item?.signatureDate).format('MM/DD/YYYY')}, ${moment(item?.signatureDate).format('h:mm a')}`}

                                </p>
                            </div>

                            <div className="signature-detail-box" style={{ width: '32%' }}>
                                <div className="flex-align-center" style={{ gap: 4 }}>
                                    <img src={Images.signatureLocation} alt="icon" />
                                    <p className="signature-detail-box-title">Location:</p>
                                </div>
                                <p className="signature-detail-box-value full-width">
                                    <TextRow name={item?.signatureLocation?.formattedAddress ? item?.signatureLocation?.formattedAddress : 'Not Set'}/>
                                </p>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
};