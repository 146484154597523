import React, { useEffect, useState } from 'react';
import { SelectTypeAutocomplete } from './selectTypeAutocomplete';
import { CloseButton, CreateChancel } from '../buttons';
import { popperUseStyles } from '../../fragments/management/core/style';
import { AddressInput } from './addressInput';
import { Images } from 'utils';
import { CustomModal } from '../modal/modalBase';

export const AddressTypeSelect = ({ title, disabled, handleSelect, value, list, name, renderValue, loadType }) => {
    const popperClasses = popperUseStyles();
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [newAddress, setNewAddress] = useState([]);
    const [error, setError] = useState(null);
    const [openModal, setOpen] = useState(false);
    const [allAddressList, setAllAddressList] = useState([]);
    const currentValue = allAddressList?.find((i) => i.id?.formattedAddress === (value?.id?.formattedAddress || value?.formattedAddress));

    useEffect(() => {
        if (newAddress && list && value) {
            let mergedList = [...newAddress, ...list];
            if (value) {
                const currentSelectedAddress = mergedList?.find((i) => i?.id?.formattedAddress === (value?.formattedAddress || value?.id?.formattedAddress));
                if (!currentSelectedAddress) {
                    mergedList = [{ name: value?.formattedAddress, id: value }, ...newAddress, ...list];
                }
            }
            setAllAddressList(mergedList);
        } else {
            setAllAddressList([...newAddress, ...list]);
        }
    }, [newAddress, list]);
    const handleSetAddress = () => {
        const addressIsValid = selectedAddress?.street && selectedAddress?.city && selectedAddress?.country;
        if (addressIsValid) {
            setSelectedAddress(null);
            setNewAddress([...newAddress, { name: selectedAddress?.formattedAddress, id: selectedAddress }]);
            setOpen(false);
            handleSelect({ target: { name: name, value: selectedAddress } });
        } else {
            setError('address');
        }
    };

    return (
        <div>
            <SelectTypeAutocomplete
                loadType={loadType}
                disabled={disabled}
                title={title}
                name={name}
                handleSelect={handleSelect}
                defaultValue={currentValue?.id}
                list={allAddressList?.length ? allAddressList : [{ name: '' }]}
                renderValue={renderValue}
                CustomPaperComponent={
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={popperClasses.autocompleteFooter}
                    >
                        <button
                            onClick={() => setOpen(true)}
                            className="add-new-address">
                            <img src={Images.addCircleBlue} alt="icon" />
                            Add New Address
                        </button>
                    </div>
                }
            />

            <CustomModal
                openDefault={!!openModal}
                handleOpenClose={() => setOpen(false)}
                content={
                    <div style={{ outline: 'none' }} className="modal-wrapper-style open-sans">
                        <div style={{ width: 448 }}>
                            <div className="modal-header-style">
                                <CloseButton handleCLic={() => setOpen(false)} />
                            </div>
                            <div className="modal-body-style">
                                <p className="modal-header-title">Add New Address</p>
                                <div className="modal-body-wrapper">
                                    <AddressInput
                                        placeholder={'Physical Address*'}
                                        selectedAddress={selectedAddress}
                                        setCurrentAddress={setSelectedAddress}
                                        errMessage={error === 'address'}
                                    />
                                </div>
                                <div>
                                    <CreateChancel
                                        create={'Add'}
                                        chancel={'Cancel'}
                                        onCreate={handleSetAddress}
                                        onClose={() => setOpen(false)}
                                        buttonWidth="300px"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            />
        </div>
    );
};