import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    CreateChancel, CustomNumericFormat,
    ErrMessage,
    SelectTypeAutocomplete,
    TimeInput, DateTypeSelect, DateTypeSelector, AddressTypeSelect, NotError, SelectAutocompletePagination
} from 'components';
import {
    ErrorText,
    FindLoad,
    Images,
    FindErrorItem,
    useModal, renderStaffName, staffStatusEnums,
} from 'utils';
import { scheduleModalsStyle } from './styles';
import { adminActions, appointmentActions, httpRequestsOnErrorsActions } from 'store';
import {
    apptOverlap,
    checkBreakRequiresFields,
    checkDateSelector, defaultInitialInputs, getStartEndAppt,
    renderTimes,
    staffOverlap,
} from './constants';
import { DRIVE, scheduleStatuses, SERVICE, UNPAID } from '../../constants';
import { ModalHeader } from './common';
import { CreateMultiple } from './createMultiple';
import { EditMultiple } from './editMultiple';
import { ConfMultiple } from './confMultiple';

export const Break = ({ handleOpenClose, type, date, modalDate }) => {
    const { allPaycodes, appts } = useSelector((state) => ({
        allPaycodes: state.admins.allPaycodes,
        appts: state.appointment.appts,
    }));
    const editActionType = modalDate ? 'EDIT_APPOINTMENT' : 'CREATE_APPOINTMENT';
    const classes = scheduleModalsStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const createModalDate = history?.location?.state;
    const [inputs, setInputs] = useState(createModalDate ? { ...createModalDate } : {});
    const [error, setError] = useState({});
    const backError = FindErrorItem(editActionType);
    const loader = FindLoad(editActionType);
    const [createMultiple, setCreateMultiple] = useState(false);
    const [occurrence, setOccurrence] = useState(0);
    const [state, setState] = React.useState([]);
    const GET_ALL_PAY_CODES = 'GET_ALL_PAY_CODES';
    const APPOINTMENT_FROM_TEMPLATE = 'APPOINTMENT_FROM_TEMPLATE';
    const { open } = useModal();
    const transformedPayCodes = allPaycodes?.map(item => ({
        ...item.payCodeTypeId,
        id: item.payCodeTypeId._id,
    }));
    const seen = new Set();
    const uniquePayCodes = transformedPayCodes?.filter((item) => {
        const payCodeId = item._id;
        if (seen.has(payCodeId)) {
            return false;
        }
        seen.add(payCodeId);
        return true;
    });
    const [apptLocations, setApptLocations] = useState({});
    const [distanceInformation, setDistanceInformation] = useState(null);

    useEffect(() => {
        if (appts?.appts?.length && inputs?.staff && inputs?.startDate && inputs?.startTime && inputs?.endTime && !distanceInformation) {
            const params = getStartEndAppt(appts, inputs);
            setApptLocations(params);
        } else {
            if (!appts?.appts?.length) {
                setApptLocations({});
                setDistanceInformation(null);
            }
        }
    }, [inputs, appts]);

    useEffect(() => {
        if (apptLocations?.from && apptLocations?.to) {
            const fromRoute = `${apptLocations?.from?.lat},${apptLocations?.from?.lng}`;
            const toRoute = `${apptLocations?.to?.lat},${apptLocations?.to?.lng}`;
            const apiKey = '448b7c0b-9165-43ff-801f-b6f2565a2e24';
            const url = `https://graphhopper.com/api/1/route?point=${fromRoute}&point=${toRoute}&vehicle=car&locale=en&calc_points=true&key=448b7c0b-9165-43ff-801f-b6f2565a2e24`;
            try {
                axios.get(url, { calc_points: true, key: apiKey, useBaseUrl: false }).then((response) => {
                    const data = response.data.paths[0];
                    const distanceInMiles = (data.distance / 1000 * 0.621371).toFixed(2);
                    const durationInMinutes = data.time / 60000;
                    const days = Math.floor(durationInMinutes / (60 * 24));
                    const hours = Math.floor((durationInMinutes % (60 * 24)) / 60);
                    const minutes = Math.floor(durationInMinutes % 60);
                    setDistanceInformation({
                        distance: distanceInMiles,
                        time: `${days ? `${days}d` : ''}${hours ? `${hours}h` : ''}${minutes ? `${minutes}m` : ''}`,
                    });
                }).catch((error) => {
                    if (error?.data?.message) {
                        toast(<NotError type={'error'} errMessage={error?.data?.message} />);
                    }
                });
            } catch (error) {
            }
        }
    }, [apptLocations]);


    useEffect(() => {
        if (error) {
            setError('');
            dispatch(httpRequestsOnErrorsActions.removeError(editActionType));
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
    }, []);

    useEffect(() => {
        if (modalDate) {
            getStaffPayCodes(modalDate?.staff?.id);
            const params = {
                type: modalDate?.type,
                staff: modalDate?.staff?.id,
                payCodeType: modalDate?.payCodeTypeId,
                // staffPayCode: modalDate?.staffPayCode?._id || modalDate?.staffPayCodeId,
                startDate: modalDate?.startDate,
                startTime: modalDate?.startTime,
                endTime: modalDate?.endTime,
            };

            if (modalDate?.miles) {
                params.miles = modalDate.miles;
            }
            setInputs(params);
        }
        if (!modalDate && date) {
            setInputs({
                startTime: date?.startTime?.slice(11, 16),
                endTime: date?.endTime?.slice(11, 16),
                startDate: date?.startDate,
            });
        }
    }, [modalDate, date]);

    const apptInfo = () => {
        const startDate = moment.utc(inputs?.startDate);
        startDate.set({
            hour: inputs?.startTime?.slice(0, 2),
            minute: inputs?.startTime?.slice(3, 5),
            second: '00',
        });
        const endDate = moment.utc(inputs?.startDate);
        endDate.set({
            hour: inputs?.endTime?.slice(0, 2),
            minute: inputs?.endTime?.slice(3, 5),
            second: '00',
        });

        const apptDate = {
            type: type,
            startDate: inputs?.startDate && moment.utc(inputs?.startDate).format('YYYY-MM-DD'),
            startTime: startDate.format(),
            endTime: endDate.format(),
            staff: inputs?.staff,
            payCodeType: inputs?.payCodeType,
            editTemplate: createMultiple,
        };
        if (type === DRIVE) {
            apptDate.miles = inputs.miles ? +inputs.miles : apptDate.miles = 0;
        }
        if (type === UNPAID) {
            delete apptDate.payCodeType;
        }
        return apptDate;
    };

    useEffect(() => {
        if (!!backError?.error === apptOverlap || !!backError?.error === staffOverlap) {
            setError(ErrorText.overlappingError('Appointments'));
            dispatch(httpRequestsOnErrorsActions.removeError('APPOINTMENT_OVERLAPPING'));
        }
    }, [backError]);

    const getStaffPayCodes = (id) => {
        dispatch(adminActions.getAllPayCodes(id));
    };

    useEffect(() => {
        if (createModalDate && createModalDate?.staff) {
            getStaffPayCodes(createModalDate.staff);
        }
    }, [createModalDate]);

    useEffect(() => {
        if (info?.staff) {
            getStaffPayCodes(info?.staff);
        }
    }, [info]);

    useEffect(() => {
        return () => dispatch(adminActions.clearAllPayCodes());
    }, []);

    const handleCloseModal = () => {
        handleOpenClose && handleOpenClose();
        setInputs('');
    };

    const handleChangeLocation = (e) => {
        setApptLocations((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        setDistanceInformation(null);
    };

    const handleChange = (e) => {
        error === e.target.name && setError('');

        if (e.target.name === 'startDate') {
            dispatch(appointmentActions.getAppointmentByFilters({
                startDate: e.target.value,
                endDate: e.target.value,
                type: SERVICE,
            }));
        }

        if (e.target.name === 'staff') {
            dispatch(adminActions.clearAllPayCodes());
            getStaffPayCodes(e.target.value);
            const newParams = {
                ...inputs,
                staff: e.target.value,
            };
            delete newParams.payCodeType;
            setInputs(newParams);
        } else {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        }

        if (error === e.target.name || error === ErrorText.timeError || error === ErrorText.overlappingError('Appointments')) {
            setError('');
        }
        if (backError) {
            dispatch(httpRequestsOnErrorsActions.removeError(backError.type));
        }
    };

    const handleChangeMiles = (value) => {
        setInputs((prevState) => ({ ...prevState, ['miles']: value?.value }));
        if (error === 'miles') setError('');
    };

    const handleCreate = () => {
        const date = apptInfo();
        const apptCreateVerification = checkBreakRequiresFields(inputs, createMultiple, type);

        if (apptCreateVerification === 'valid') {
            if (modalDate?.id) {
                dispatch(appointmentActions.editAppointment(date, modalDate?.id));
            } else {
                dispatch(appointmentActions.createAppointment(date));
            }
        } else {
            setError(apptCreateVerification);
        }
    };

    function createMultipleAppt() {
        const week = {
            startDate: new Date(inputs.startDate),
            endDate: new Date(inputs.endDate),
            mode: inputs.mode,
            repeatCountWeek: +inputs.repeatCountWeek,
            repeatCheckWeek: [...state],
        };

        const mounthObject = {
            startDate: new Date(inputs.startDate),
            endDate: new Date(inputs.endDate),
            mode: inputs.mode,
            repeatDayMonth: +inputs.repeatDayMonth,
            repeatMonth: +inputs.repeatMonth,
        };

        !inputs.repeatDayMonth ? delete mounthObject['repeatDayMonth'] : '';
        !inputs.repeatMonth ? delete mounthObject['repeatMonth'] : '';


        const newObject = {
            startDate: new Date(inputs.startDate),
            endDate: new Date(inputs.endDate),
            mode: inputs.mode,
        };


        if (inputs.repeatConsecutive === 'repeatConsecutive') {
            newObject.repeatConsecutive = true;
        } else {
            newObject.repeatCount = +inputs.repeatCount;
        }


        const obj =
            inputs.mode === 'WEEKLY' ? week
                : inputs.mode === 'MONTHLY' ? mounthObject :
                    newObject;


        const startTime = moment.utc(obj?.startDate);
        startTime.set({
            hour: inputs?.startTime?.slice(0, 2),
            minute: inputs?.startTime?.slice(3, 5),
            second: '00',
        });
        const endTime = moment.utc(obj?.endDate);
        endTime.set({
            hour: inputs?.endTime?.slice(0, 2),
            minute: inputs?.endTime?.slice(3, 5),
            second: '00',
        });


        if (modalDate?.id && createMultiple) {
            const apptEditVerification = checkBreakRequiresFields(inputs, false, type, state);

            if (apptEditVerification === 'valid') {

                const startTime = moment.utc(modalDate?.startDate);
                startTime.set({
                    hour: inputs?.startTime?.slice(0, 2),
                    minute: inputs?.startTime?.slice(3, 5),
                    second: '00',
                });
                const endTime = moment.utc(modalDate?.startDate);
                endTime.set({
                    hour: inputs?.endTime?.slice(0, 2),
                    minute: inputs?.endTime?.slice(3, 5),
                    second: '00',
                });

                const modalInfo = {
                    ...modalDate,
                };
                modalInfo.startTime = startTime.format();
                modalInfo.endTime = endTime.format();

                open(
                    <EditMultiple
                        modalDate={modalInfo}
                        handleSubmit={() => dispatch(appointmentActions.editAppointment({
                                ...apptInfo(),
                                startTime: startTime.format(),
                                endTime: endTime.format(),
                            }, modalDate?.id),
                        )}
                        loadType={editActionType}
                        occurrence={occurrence}
                    />,
                );
            } else {
                setError(apptEditVerification);
            }

        } else {
            const apptVerification = checkBreakRequiresFields(inputs, createMultiple, type, state);
            if (apptVerification === 'valid') {
                open(
                    <ConfMultiple
                        recurInfo={obj}
                        handleSubmit={() => dispatch(appointmentActions.appointmentFromTemplate({
                            pattern: { ...obj },
                            apptData: {
                                staffId: inputs?.staff,
                                ...apptInfo(),
                                template: {
                                    ...obj,
                                },
                            },
                        }))}
                        loadType={APPOINTMENT_FROM_TEMPLATE}
                        occurrence={occurrence}
                        checkParams={{
                            staffId: inputs?.staff,
                            startTime: startTime.format(),
                            endTime: endTime.format(),
                        }}
                    />,
                );
            } else {
                setError(apptVerification);
            }
        }
    }

    const setMultipleAppts = () => {
        setCreateMultiple(!createMultiple);
        if (modalDate) {
            const params = {
                ...inputs,
            };
            if (!createMultiple) {
                delete params.startDate;
                delete params.endDate;
            } else {
                delete params.endDate;
                params.startDate = modalDate.startDate;
            }
            setInputs(params);
        } else {
            const params = {
                ...inputs,
                ...defaultInitialInputs,
            };
            delete params.startDate;
            delete params.endDate;
            setInputs(params);
        }
    };

    const changeDates = (value) => {
        const params = {
            ...inputs,
        };

        value?.start ? params.startDate = value.start : delete params.startDate;
        value?.end ? params.endDate = value.end : delete params.endDate;

        setInputs(params);
        if (error === ErrorText.dateError || error === 'startDate' || error === 'endDate') {
            setError('');
        }
    };

    const editRepeat = (e) => {
        setInputs(e);
        if (error === 'repeat') {
            setError('');
        }
    };

    return (
        <>
            <ModalHeader
                modalDate={modalDate}
                type={type}
                createMultiple={createMultiple}
                setMultipleAppts={setMultipleAppts}
            />

            <div className={classes.breakWrapper}>
                <div className="flex-align-start full-width" style={{ gap: 24 }}>
                    <SelectAutocompletePagination
                        style={'full-width'}
                        defaultStatuses={[staffStatusEnums.ACTIVE]}
                        title={'Staff Member'}
                        name={'staff'}
                        handleSelect={handleChange}
                        defaultValue={inputs?.staff}
                        renderValue={(i) => renderStaffName(i)}
                        type={'staff'}
                        error={error}
                        typeError={error === 'staff' ? `Staff member ${ErrorText.isRequired}` : ''}
                        currentSelected={modalDate?.staff && modalDate?.staff}
                    />
                    {type !== UNPAID &&
                        <SelectTypeAutocomplete
                            defaultSelected={modalDate?.payCodeTypeName && {name: modalDate?.payCodeTypeName}}
                            defaultValue={inputs?.payCodeType}
                            list={uniquePayCodes || []}
                            style={'full-width'}
                            disabled={modalDate?.status === scheduleStatuses?.COMPLETED ? true : !inputs?.staff}
                            loadType={GET_ALL_PAY_CODES}
                            title={'Staff Paycode*'}
                            name={'payCodeType'}
                            handleSelect={handleChange}
                            error={error}
                            typeError={error === 'payCodeType' ? `Staff paycode ${ErrorText.isRequired}` : ''}
                            renderValue={(i) => i?.name}
                        />
                    }
                </div>

                <div className="flex-align-start" style={{ gap: 24 }}>
                    {checkDateSelector(modalDate, createMultiple) &&
                        <div className="full-width">
                            <p className="date-time-text">{createMultiple ? 'Date Range' : 'Date'}</p>
                            {createMultiple ?
                                <DateTypeSelector
                                    startName={'start'}
                                    endName={'end'}
                                    type={'modalInput'}
                                    outLabel={'Date Range*'}
                                    handleGetDates={changeDates}
                                    filters={{
                                        start: inputs?.startDate,
                                        end: inputs?.endDate,
                                    }}
                                    error={
                                        error === 'startDate' ? `Start date ${ErrorText.isRequired}` :
                                            error === 'endDate' ? `End date ${ErrorText.isRequired}` :
                                                error === ErrorText.dateError ? ErrorText.dateError :
                                                    ''
                                    }
                                />
                                :
                                <DateTypeSelect
                                    type={'modalInput'}
                                    name={'startDate'}
                                    outLabel={'Start Date*'}
                                    handleGetDates={handleChange}
                                    values={inputs}
                                    error={error === 'startDate' && `Start date ${ErrorText.isRequired}`}
                                />
                            }
                        </div>
                    }

                    <div className="full-width">
                        <p className="date-time-text">{'Time'}</p>
                        <div className={classes.timeInputs} style={{ gap: '16px' }}>
                            <TimeInput
                                label={'Start Time*'}
                                name={'startTime'}
                                onChange={handleChange}
                                typeError={
                                    error === 'startTime' ? `Start time ${ErrorText.isRequired}` :
                                        backError?.error === apptOverlap ? ErrorText.overlappingError('Appointments') :
                                            backError?.error === staffOverlap ? ErrorText.overlappingError('Appointments') :
                                                ''
                                }
                                defaultValue={
                                    inputs?.startTime ? renderTimes(inputs.startTime) || inputs.startTime : null
                                }
                            />
                            <TimeInput
                                label={'End Time*'}
                                name={'endTime'}
                                onChange={handleChange}
                                typeError={
                                    error === 'endTime' ? `End time ${ErrorText.isRequired}` :
                                        error === ErrorText.timeError ? ErrorText.timeError
                                            : ''
                                }
                                defaultValue={
                                    inputs?.endTime ? renderTimes(inputs.endTime) || inputs.endTime : null
                                }
                            />
                        </div>
                    </div>
                </div>

                {type === DRIVE && (
                    <div>
                        <div className="flex-align-start" style={{ gap: 24 }}>
                            <div className="full-width">
                                <p className="date-time-text">From</p>
                                <AddressTypeSelect
                                    disabled={!inputs?.startDate || !inputs?.startTime || !inputs?.endTime}
                                    title={'Starting Address'}
                                    name={'from'}
                                    handleSelect={handleChangeLocation}
                                    value={apptLocations?.from}
                                    list={apptLocations?.from ? [apptLocations?.from] : []}
                                    renderValue={(i) => i?.name}
                                />
                            </div>
                            <div className="full-width">
                                <p className="date-time-text">To</p>
                                <AddressTypeSelect
                                    disabled={!inputs?.startDate || !inputs?.startTime || !inputs?.endTime}
                                    title={'Ending Address'}
                                    name={'to'}
                                    handleSelect={handleChangeLocation}
                                    value={apptLocations?.to}
                                    list={apptLocations?.to ? [apptLocations?.to] : []}
                                    renderValue={(i) => i?.name}
                                />
                            </div>
                        </div>

                        {distanceInformation?.distance &&
                            <div className="apply-miles-wrapper">
                                <div className="flex-align-center" style={{ gap: 4 }}>
                                    <p>Estimated Time & Distance:</p>
                                    <span>{`${distanceInformation?.time ? `${distanceInformation?.time} | ` : ''}  ${distanceInformation?.distance} miles`}</span>
                                </div>
                                <button
                                    onClick={() => {
                                        setInputs({
                                            ...inputs,
                                            miles: distanceInformation?.distance,
                                        });
                                    }}
                                >Apply Miles
                                </button>
                            </div>
                        }

                        <div className="flex-align-start" style={{ gap: 24 }}>
                            <div className="full-width">
                                <CustomNumericFormat
                                    name={'miles'}
                                    label={'Miles'}
                                    value={inputs?.miles}
                                    error={error === 'miles' ? `Miles ${ErrorText.isRequired}` : ''}
                                    handleChangeMiles={handleChangeMiles}
                                    thousandSeparator={','}
                                    errorName={error}
                                />
                            </div>
                            <div className="full-width" />
                        </div>

                    </div>
                )}

                {backError?.error === 'TB credential expired' &&
                    <ErrMessage
                        styles={{ margin: '0 0 16px' }}
                        text={backError?.error === 'TB credential expired' ? 'TB credential expired' : ''}
                    />
                }

                {modalDate?.status === scheduleStatuses?.COMPLETED &&
                    <div
                        style={{ margin: '0 0 24px' }}
                        className={classes.warning}
                    >
                        <img src={Images.warning} alt="icon" />
                        <p>Warning! Since this appointment is already complete, processed inputsheets will not be
                            updated</p>
                    </div>
                }

                {createMultiple &&
                    <CreateMultiple
                        handleClose={handleOpenClose}
                        modalDate={modalDate}
                        state={state}
                        setState={setState}
                        inputs={{ ...inputs }}
                        setInputs={editRepeat}
                        setOccurrence={setOccurrence}
                        error={error}
                    />
                }

                <div className={classes.actionBtnBox}>
                    <CreateChancel
                        loader={!!loader.length}
                        create={
                            modalDate?.id && createMultiple ? 'Update' :
                                modalDate ? 'Save' :
                                    createMultiple ? `Add ${occurrence ? occurrence : 0} Appointments` :
                                        'Add Appointment'
                        }
                        chancel={'Cancel'}
                        onCreate={createMultiple ? createMultipleAppt : handleCreate}
                        onClose={handleCloseModal}
                        buttonWidth="100%"
                    />
                </div>
            </div>
        </>
    );
};



