import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { CustomDelete, FullTable } from 'components';
import { FindSuccessItem, Images, useModal } from 'utils';
import { adminActions, httpRequestsOnSuccessActions } from 'store';
import { ACTION_TYPE, payCodeBody, payCodeHead } from './constants';
import { staffPaycodeNotYet } from '../constants';
import { PaycodeModal } from '../modals';

const DELETE_ACTION_TYPE = 'DELETE_STAFF_PAY_CODE';
const CREATE_ACTION_TYPE = 'CREATE_PAY_CODE';
export const StaffEmpPaycodes = ({}) => {
    const payCodes = useSelector((state) => state.admins.payCodes);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const info = history?.location?.state;
    const { open, close } = useModal();
    const success = FindSuccessItem(CREATE_ACTION_TYPE);

    const renderParams = () => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        delete pushInfo.activeTab;
        delete pushInfo.limit;
        delete pushInfo.skip;
        return pushInfo;
    };

    const getPayCodes = (loading) => {
        dispatch(adminActions.getPayCode(params?.id, renderParams(), loading));
    };

    useEffect(() => {
        if (info) {
            getPayCodes();
        }
    }, [info]);

    useEffect(() => {
        if (success) {
            close()
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ACTION_TYPE));
            getPayCodes('noLoad');
        }
    }, [success]);

    const handleInactivate = (item, name) => {
        if (name === 'edit') {
            open(<PaycodeModal item={item} requestParams={renderParams()} />);
        }
        if (name === 'delete') {
            open(
                <CustomDelete
                    info="Delete Paycode"
                    text="Are you sure you want to delete this paycode? It will be permanently removed"
                    innerText="Delete Paycode"
                    handleDel={() => dispatch(adminActions.deletePayCode(item?.id))}
                    actionType={DELETE_ACTION_TYPE}
                    afterSuccess={() => getPayCodes('noLoad')}
                    body={
                        <div>
                            <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                                <img style={{ marginTop: '3px' }} src={Images.coinStack} alt="icon" />
                                <p>{item?.payCodeTypeId?.name}</p>
                            </div>
                        </div>
                    }
                />,
            );
        }
    };

    return (
        <FullTable
            margin={'0'}
            head={payCodeHead}
            body={payCodeBody(handleInactivate)}
            loadingType={ACTION_TYPE}
            list={payCodes?.filter((i) => i?.payCodeTypeId)}
            notYet={staffPaycodeNotYet}
        />
    );
};
