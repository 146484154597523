import { BREAK, DRIVE, PAID, scheduleStatuses, SERVICE, SICK_TIME, STAFF, UNPAID } from '../../constants';
import { ErrorText, formatAMPMNeW, Images, isNotEmpty, PermissionList, RolePermission, TimeIsGreat } from 'utils';
import React from 'react';
import { scheduleModalsStyle } from './styles';
import { TextRow } from 'components';
import moment from 'moment/moment';
import axios from 'axios';

export function getDynamicContent(content, info, type) {
    const staticText = content === 'TITLE' ? '' :
        content === 'SUBTITLE' ? ', please fulfill the below fields.' : '';

    const dynamicText =
        content === 'TITLE' ? info ? 'Edit ' : 'Add' :
            content === 'SUBTITLE' ? info ? 'To edit the' : 'To add a' : '';

    switch (type) {
        case PAID:
            return `${dynamicText} Paid Time Off${staticText}`;
        case STAFF:
            return `${dynamicText} Staff Appointment${staticText}`;
        case BREAK:
            return `${dynamicText} Break${staticText}`;
        case DRIVE:
            return `${dynamicText} Drive${staticText}`;
        case SERVICE:
            return `${dynamicText} Service Appointment${staticText}`;
        case UNPAID:
            return `${dynamicText} Unpaid Time Off${staticText}`;
        case SICK_TIME:
            return `${dynamicText} Sick Time ${staticText}`;
        default:
            return '';
    }
}

export function getDynamicTitle(content, info, type) {

    const dynamicText =
        content === 'TITLE' ? info ? 'Edit ' : 'Add' :
            content === 'SUBTITLE' ? info ? 'To edit the' : 'To add a' : '';

    switch (type) {
        case PAID:
            return `${dynamicText} Paid Time Off Appointment`;
        case STAFF:
            return `${dynamicText} Staff Appointment`;
        case BREAK:
            return `${dynamicText} Break Appointment`;
        case DRIVE:
            return `${dynamicText} Drive Appointment`;
        case SERVICE:
            return `${dynamicText} Service Appointment`;
        case UNPAID:
            return `${dynamicText} Unpaid Time Off Appointment`;
        case SICK_TIME:
            return `${dynamicText} Sick Time Appointment`;
        default:
            return '';
    }
}


export const renderTimes = (item) => {
    return item?.slice(11, 16);
};

export function timeToMinutes(time) {
    const [hours, minutes] = time.trim().split(':').map(Number);
    return hours * 60 + minutes;
}

export const renderUnitWarnings = (currentService, inputs) => {
    const unitSize = currentService?.service?.unitSize;
    const startMinutes = inputs?.startTime && timeToMinutes(inputs?.startTime);
    const endMinutes = inputs?.endTime && timeToMinutes(inputs?.endTime);
    const maxUnit = currentService?.service?.max;
    const minUnit = currentService?.service?.min;
    const currentApptUnites = (endMinutes - startMinutes) / unitSize;

    return {
        uniteSizeCorrect: inputs?.startTime && inputs?.endTime && inputs?.authService ? (endMinutes - startMinutes) % unitSize === 0 : true,
        unitSizeCountIsCorrect:
            inputs?.startTime && inputs?.endTime && inputs?.authService ?
                currentApptUnites <= maxUnit && currentApptUnites >= minUnit
                : true,
        currentApptUnites: currentApptUnites,
        minUnit: minUnit,
        maxUnit: maxUnit,
        unitSize: unitSize,
    };
};


export const RenderWarnings = ({
                                   uniteSizeCorrect,
                                   unitSizeCountIsCorrect,
                                   unitSize,
                                   minUnit,
                                   maxUnit,
                                   currentApptUnites,
                                   modalDate,
                                   warnAuthorization,
                                   checkAvailableUnits,
                                   availableUnits,
                                   // frequencyLimit,
                                   // currentService,
                                   // inputs,
                                   // createMultiple
                               }) => {
    const classes = scheduleModalsStyle();
    // const checkMultipleFrequency =  createMultiple ? inputs?.endDate : true;

    return (
        <div className={classes.warningBox}>
            {!uniteSizeCorrect &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <div>
                        <p>Duration results in partial units since</p>
                        <p>{`1 unit = ${unitSize} minutes.`}</p>
                    </div>
                </div>
            }

            {!unitSizeCountIsCorrect &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <div>
                        <p>
                            Selected
                            <span style={{
                                color: '#DE350B',
                                fontWeight: 600,
                                margin: '0 4px',
                            }}>{currentApptUnites.toFixed(2)}</span>units
                            <span>{`
                           is outside the allowed range of  ${minUnit} - ${maxUnit} units, (15-60 mins) for this funding source. 1 unit = ${unitSize} mins.
                            `}</span>
                        </p>
                    </div>
                </div>
            }

            {checkAvailableUnits < 0 && (
                <div className={classes.warning}>
                    <img src={Images.warning} alt="warning" />
                    <p>
                        Authorized units exceeded, available units are
                        <span style={{ fontWeight: 600, marginLeft: 4 }}>{availableUnits?.toFixed(2)}</span>
                    </p>
                </div>
            )}

            {modalDate?.status === scheduleStatuses?.COMPLETED &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <p>
                        Warning! Since this appointment is already rendered,
                        claims and processed timesheets will not be updated
                    </p>
                </div>
            }

            {warnAuthorization?.status === 'INACTIVE' &&
                <div className={classes.warning}>
                    <img src={Images.warning} alt="icon" />
                    <p>Authorization is inactive.</p>
                </div>
            }


            {/*{(inputs?.startDate && inputs?.startTime && inputs?.endTime && inputs?.authService && checkMultipleFrequency) && frequencyLimit === false &&*/}
            {/*    <div>*/}
            {/*        {RolePermission([PermissionList?.APPT_CREATE_FREQUENCY?.code]) ?*/}
            {/*            <div className={classes.warning}>*/}
            {/*                <img src={Images.warning} alt="icon" />*/}
            {/*                <p>You are exceeding the allowed limit of <span style={{*/}
            {/*                    color: '#DE350B',*/}
            {/*                    fontWeight: 600,*/}
            {/*                    margin: '0 4px',*/}
            {/*                }}>{currentService?.frequencyLimit?.limit}</span> units for this service.</p>*/}
            {/*            </div>*/}
            {/*            :*/}
            {/*            <div className={classes.errorWrapper}>*/}
            {/*                <img src={Images.error} alt="icon" />*/}
            {/*                <p>You are exceeding the allowed limit of <span style={{*/}
            {/*                    color: '#DE350B',*/}
            {/*                    fontWeight: 600,*/}
            {/*                    margin: '0 4px',*/}
            {/*                }}>{currentService?.frequencyLimit?.limit}</span> units for this service.</p>*/}
            {/*            </div>*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*}*/}

        </div>
    );
};

export const isDateGreater = (date1, date2, createMultiple) => {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    if (date1 && date2 && !createMultiple) {
        return d1.getTime() > d2.getTime();
    } else {
        return true;
    }
};

export const apptOverlap = 'appointment overlapping';
export const staffOverlap = 'Staff overlap on appointment';


export const moreApptHead = [
    { name: '', title: 'Start/End Time', noSearch: true, custom: false },
    { name: '', title: 'Staff Member', width: '120px', noSearch: true, custom: false },
    { name: '', title: 'Client', icon: 'date', noSearch: true, custom: false },
    { name: '', title: 'Authorized Serivce', noSearch: true, custom: false },
];

export const moreApptBody = [
    { rowText: (item) => `${formatAMPMNeW(item?.originalStart)} - ${formatAMPMNeW(item?.originalEnd)}` },
    {
        rowText: (item) => <TextRow
            name={item?.staffName ? `${item?.staffName?.firstName} ${item?.staffName?.lastName ? item?.staffName?.lastName : ''}` : 'N/A'} />,
    },
    {
        rowText: (item) => <TextRow name={item?.client ?
            `${item?.client?.code ? `(${item?.client?.code})` : ''} ${item?.client?.firstName} ${item?.client?.lastName ? item?.client?.lastName : ''} `
            :
            'N/A'} />,
    },
    { rowText: (item) => <TextRow name={item?.serviceCptCode ? item?.serviceCptCode : 'N/A'} /> },

];


export const checkApptRequires = (modalDate) => {
    return (RolePermission([PermissionList.APPT_SIGNATURE_TOGGLE_MANAGE?.code]) &&
        (modalDate?.status ? modalDate?.status === scheduleStatuses?.PENDING : true) ||
        RolePermission([PermissionList.APPT_NOTE_TOGGLE_MANAGE?.code]) &&
        (modalDate?.status ? modalDate?.status === scheduleStatuses?.PENDING : true)
    );
};

export const checkApptRequiresFields = (inputs, createMultiple, state) => {

    const timeComparingIsValid = !!inputs.startTime && !!inputs.endTime && TimeIsGreat(inputs.startTime, inputs.endTime);
    const dateComparingIsValid = new Date(inputs.startDate).getTime() < new Date(inputs.endDate).getTime();
    const repeat = !!inputs.repeatCount ? inputs.repeatCount : !!inputs.repeatConsecutive ? inputs.repeatConsecutive : '';

    if (createMultiple) {
        const required =
            isNotEmpty(inputs.client) &&
            isNotEmpty(inputs.authorizedService) &&
            isNotEmpty(inputs.authService) &&
            isNotEmpty(inputs.staff) &&
            isNotEmpty(inputs?.payCodeType) &&
            // isNotEmpty(inputs?.staffPayCode) &&
            isNotEmpty(inputs.placeService) &&
            inputs?.startDate &&
            inputs?.endDate &&
            inputs.startTime &&
            inputs.endTime &&
            timeComparingIsValid &&
            dateComparingIsValid &&
            inputs.mode === 'DAILY' ? !!repeat :
                inputs.mode === 'WEEKLY' ? !!inputs.repeatCountWeek && !!state.length :
                    !!inputs.repeatDayMonth && !!inputs.repeatMonth;

        const requiredError =
            !isNotEmpty(inputs.client) ? 'client' :
                !isNotEmpty(inputs.authorizedService) ? 'authorizedService' :
                    !isNotEmpty(inputs.authService) ? 'authService' :
                        !isNotEmpty(inputs?.staff) ? 'staff' :
                            !isNotEmpty(inputs?.payCodeType) ? 'payCodeType' :
                                // !isNotEmpty(inputs?.staffPayCode) ? 'staffPayCode' :
                                !isNotEmpty(inputs.placeService) ? 'placeService' :
                                    !inputs.startDate ? 'startDate' :
                                        !inputs.endDate ? 'endDate' :
                                            !inputs.startTime ? 'startTime' :
                                                !inputs.endTime ? 'endTime' :
                                                    !timeComparingIsValid ? ErrorText.timeError :
                                                        !dateComparingIsValid ? ErrorText.dateError :
                                                            !repeat ? 'repeat' :

                                                                '';

        if (required) {
            return 'valid';
        } else {
            return requiredError;
        }
    } else {

        const required =
            isNotEmpty(inputs.client) &&
            isNotEmpty(inputs.authorizedService) &&
            isNotEmpty(inputs.authService) &&
            isNotEmpty(inputs.staff) &&
            isNotEmpty(inputs?.payCodeType) &&
            // isNotEmpty(inputs?.staffPayCode) &&
            isNotEmpty(inputs.placeService) &&
            inputs?.startDate &&
            inputs.startTime &&
            inputs.endTime &&
            timeComparingIsValid;

        const requiredError =
            !isNotEmpty(inputs.client) ? 'client' :
                !isNotEmpty(inputs.authorizedService) ? 'authorizedService' :
                    !isNotEmpty(inputs.authService) ? 'authService' :
                        !isNotEmpty(inputs?.staff) ? 'staff' :
                            !isNotEmpty(inputs?.payCodeType) ? 'payCodeType' :
                                // !isNotEmpty(inputs?.staffPayCode) ? 'staffPayCode' :
                                !isNotEmpty(inputs.placeService) ? 'placeService' :
                                    !inputs.startDate ? 'startDate' :
                                        !inputs.startTime ? 'startTime' :
                                            !inputs.endTime ? 'endTime' :
                                                !timeComparingIsValid ? ErrorText.timeError :
                                                    '';

        if (required) {
            return 'valid';
        } else {
            return requiredError;
        }
    }

};

export const checkBreakRequiresFields = (inputs, createMultiple, type, state) => {
    const timeComparingIsValid = !!inputs.startTime && !!inputs.endTime && TimeIsGreat(inputs.startTime, inputs.endTime);
    const dateComparingIsValid = createMultiple ?
        inputs?.startDate && inputs?.endDate && new Date(inputs?.startDate).getTime() < new Date(inputs?.endDate).getTime()
        :
        true;

    const repeat =
        !!inputs.repeatCount ? inputs.repeatCount :
            !!inputs.repeatConsecutive ? inputs.repeatConsecutive : null;

    const checkDateTimeValid = dateComparingIsValid && inputs.startTime && inputs.endTime && timeComparingIsValid;
    const dateTimeError =
        !inputs?.startDate ? 'startDate' :
            createMultiple && !inputs?.endDate ? 'endDate' :
                !dateComparingIsValid ? ErrorText.dateError :
                    !inputs.startTime ? 'startTime' :
                        !inputs.endTime ? 'endTime' :
                            !timeComparingIsValid ? ErrorText.timeError :
                                null;


    const checkMultiple = createMultiple ?
        inputs.mode === 'DAILY' ? !!repeat :
            inputs.mode === 'WEEKLY' ? !!inputs.repeatCountWeek && !!state.length :
                !!inputs.repeatDayMonth && !!inputs.repeatMonth
        : true;

    const checkRepeatError =
        inputs.mode === 'DAILY' ? !repeat ? 'repeat' : '' :
            inputs.mode === 'WEEKLY' ? !inputs.repeatCountWeek ? 'repeat' : !state.length ? 'repeat' : '' :
                !inputs.repeatDayMonth ? 'repeat' : !inputs.repeatMonth ? 'repeat' : '';


    if (type === STAFF || type === SICK_TIME || type === BREAK || type === DRIVE || type === PAID) {
        if (isNotEmpty(inputs?.staff) && isNotEmpty(inputs?.payCodeType) && checkDateTimeValid && checkMultiple) {
            return 'valid';
        } else {
            return !inputs?.staff ? 'staff' :
                !inputs?.payCodeType ? 'payCodeType' :
                    // !inputs?.staffPayCode ? 'staffPayCode' :
                    dateTimeError ? dateTimeError :
                        !repeat ? 'repeat' :
                            checkRepeatError ? checkRepeatError :
                                '';
        }
    }
    if (type === UNPAID) {
        if (isNotEmpty(inputs?.staff) && checkDateTimeValid && checkMultiple) {
            return 'valid';
        } else {
            return !inputs?.staff ? 'staff' :
                dateTimeError ? dateTimeError :
                    !repeat ? 'repeat' :
                        checkRepeatError ? checkRepeatError :
                            '';
        }
    }


};


export const checkDateSelector = (modalDate, createMultiple) => {
    if (modalDate?.template) {
        return !createMultiple;
    } else {
        return true;
    }

};


export const defaultInitialInputs = {
    mode: 'DAILY',
    repeatCountWeek: '1',
    repeatDayMonth: '',
    repeatMonth: '1',
    repeatConsecutive: '',
    repeatCount: '1',
    repeatCountCheckbox: 'repeatCountCheckbox',
};


export const getStartEndAppt = (appts, inputs) => {
    const apptFilterredList = appts?.appts?.filter((i) => i?.location?._id);
    const parseTime = (date, time) => moment.utc(`${date}T${time.trim()}:00Z`);
    const inputStartTime = parseTime(inputs.startDate, inputs.startTime);
    const inputEndTime = parseTime(inputs.startDate, inputs.endTime);

    const startAppt = apptFilterredList?.find(appt => {
        const apptStartTime = moment.utc(appt.startTime);
        const timeDifferenceInMinutes = inputStartTime.diff(apptStartTime, 'minutes');
        return (appt.startDate === inputs.startDate && timeDifferenceInMinutes >= -60 && timeDifferenceInMinutes <= 0);
    });

    const endAppt = apptFilterredList?.find(appt => {
        const apptStartTime = moment.utc(appt.startTime);
        const timeDifferenceInMinutes = inputEndTime.diff(apptStartTime, 'minutes');
        return (appt.startDate === inputs.startDate && timeDifferenceInMinutes >= -60 && timeDifferenceInMinutes <= 0);
    });

    const params = {};
    startAppt ? params.from = startAppt?.location : delete params.from;
    endAppt ? params.to = endAppt?.location : delete params.to;
    return params;
};

export const getDestinationFromAddresses = (apptLocations) => {
    const fromRoute = `${apptLocations?.from?.lat},${apptLocations?.from?.lng}`;
    const toRoute = `${apptLocations?.to?.lat},${apptLocations?.to?.lng}`;
    const apiKey = '448b7c0b-9165-43ff-801f-b6f2565a2e24';
    const url = `https://graphhopper.com/api/1/route?point=${fromRoute}&point=${toRoute}&vehicle=car&locale=en&calc_points=true&key=448b7c0b-9165-43ff-801f-b6f2565a2e24`;
    try {
        axios.get(url, { calc_points: true, key: apiKey, useBaseUrl: false }).then((response) => {
            const data = response.data.paths[0];
            const distanceInMiles = (data.distance / 1000 * 0.621371).toFixed(2);
            const durationInMinutes = data.time / 60000;
            const days = Math.floor(durationInMinutes / (60 * 24));
            const hours = Math.floor((durationInMinutes % (60 * 24)) / 60);
            const minutes = Math.floor(durationInMinutes % 60);

            return {
                distance: distanceInMiles,
                time: `${days ? `${days}d` : ''}${hours ? `${hours}h` : ''}${minutes ? `${minutes}m` : ''}`,
            }
            // setDistanceInformation({
            //     distance: distanceInMiles,
            //     time: `${days ? `${days}d` : ''}${hours ? `${hours}h` : ''}${minutes ? `${minutes}m` : ''}`,
            // });
        }).catch((error) => {});
    } catch (error) {
        return { }
    }
}

export const signatureTypeEnums = {
    SIGNATURE : 'SIGNATURE',
    PROVIDER_SIGNATURE : 'PROVIDER_SIGNATURE',
}