/**Get clients */
export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";

export const GET_BASIC_CLIENTS = "GET_BASIC_CLIENTS";
export const GET_BASIC_CLIENTS_SUCCESS = "GET_BASIC_CLIENTS_SUCCESS";

export const GET_BASIC_ACTIVE_CLIENTS = "GET_BASIC_ACTIVE_CLIENTS";
export const GET_BASIC_ACTIVE_CLIENTS_SUCCESS = "GET_BASIC_ACTIVE_CLIENTS_SUCCESS";
/**Create clients */
export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";

/**Delete clients */
export const DELETE_CLIENT = "DELETE_CLIENT";

/**Edit clients */
export const EDIT_CLIENT = "EDIT_CLIENT";

export const SET_CLIENT_STATUS = "SET_CLIENT_STATUS";
export const EDIT_CLIENT_STATUS = "EDIT_CLIENT_STATUS";

/**get client by id */
export const GET_CLIENT_BY_ID = "GET_CLIENT_BY_ID";
export const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS";

/**get client contacts */
export const GET_CLIENT_CONTACTS = "GET_CLIENT_CONTACTS";
export const GET_CLIENT_CONTACTS_SUCCESS = "GET_CLIENT_CONTACTS_SUCCESS";

/** Create client contact **/
export const CREATE_CLIENT_CONTACT = "CREATE_CLIENT_CONTACT";
export const CREATE_CLIENT_CONTACT_SUCCESS = "CREATE_CLIENT_CONTACT_SUCCESS";

/** Edit client contact **/
export const EDIT_CLIENT_CONTACT = "EDIT_CLIENT_CONTACT";

/** Delete client contact **/
export const DELETE_CLIENT_CONTACT = "DELETE_CLIENT_CONTACT";

/** Client Enrollment */
export const GET_CLIENT_ENROLLMENT = "GET_CLIENT_ENROLLMENT";
export const GET_CLIENT_ENROLLMENT_SUCCESS = "GET_CLIENT_ENROLLMENT_SUCCESS";

export const CREATE_CLIENT_ENROLLMENT = "CREATE_CLIENT_ENROLLMENT";

export const EDIT_CLIENT_ENROLLMENT = "EDIT_CLIENT_ENROLLMENT";

export const DELETE_CLIENT_ENROLLMENT = "DELETE_CLIENT_ENROLLMENT";

export const TERMINATE_CLIENT_ENROLLMENT = "TERMINATE_CLIENT_ENROLLMENT";

export const CHANGE_ENROLLMENT_TYPE = "CHANGE_ENROLLMENT_TYPE";
/** End */



/** Client Authorization */
export const GET_CLIENT_AUTHORIZATION = "GET_CLIENT_AUTHORIZATION";
export const GET_CLIENT_AUTHORIZATION_SUCCESS = "GET_CLIENT_AUTHORIZATION_SUCCESS";
export const GET_CLIENT_AUTHORIZATION_ERROR = "GET_CLIENT_AUTHORIZATION_ERROR";

export const GET_AUTH_UNIT_INFO = "GET_AUTH_UNIT_INFO";
export const GET_AUTH_UNIT_INFO_SUCCESS = "GET_AUTH_UNIT_INFO_SUCCESS";


export const CREATE_CLIENT_AUTHORIZATION = "CREATE_CLIENT_AUTHORIZATION";

export const EDIT_CLIENT_AUTHORIZATION = "EDIT_CLIENT_AUTHORIZATION";

export const DELETE_CLIENT_AUTHORIZATION = "DELETE_CLIENT_AUTHORIZATION";
/** End */

export const GET_CLIENT_AUTHORIZATION_SERV = "GET_CLIENT_AUTHORIZATION_SERV";
export const GET_CLIENT_AUTHORIZATION_SERV_SUCCESS = "GET_CLIENT_AUTHORIZATION_SERV_SUCCESS";
export const GET_CLIENT_AUTHORIZATION_SERV_ERROR = "GET_CLIENT_AUTHORIZATION_SERV_ERROR";

/**create client Authorizations Service */
export const CREATE_CLIENT_AUTHORIZATION_SERV = "CREATE_CLIENT_AUTHORIZATION_SERV";

/**edit client Authorizations service */
export const EDIT_CLIENT_AUTHORIZATION_SERV = "EDIT_CLIENT_AUTHORIZATION_SERV";

/**delete client Authorizations service */
export const DELETE_CLIENT_AUTHORIZATION_SERV = "DELETE_CLIENT_AUTHORIZATION_SERV";

/**create client Authorization File */
export const CREATE_CLIENT_AUTHORIZATION_FILE = "CREATE_CLIENT_AUTHORIZATION_FILE";
export const CREATE_CLIENT_AUTHORIZATION_FILE_SUCCESS =
   "CREATE_CLIENT_AUTHORIZATION_FILE_SUCCESS";

/**get client Authorization File */
export const GET_CLIENT_AUTHORIZATION_FILE = "GET_CLIENT_AUTHORIZATION_FILE";
export const GET_CLIENT_AUTHORIZATION_FILE_SUCCESS =
   "GET_CLIENT_AUTHORIZATION_FILE_SUCCESS";

/**edit client Authorization File */
export const EDIT_CLIENT_AUTHORIZATION_FILE = "EDIT_CLIENT_AUTHORIZATION_FILE";
export const EDIT_CLIENT_AUTHORIZATION_FILE_SUCCESS =
   "EDIT_CLIENT_AUTHORIZATION_FILE_SUCCESS";

/**delete client Authorization File */
export const DELETE_CLIENT_AUTHORIZATION_FILE = "DELETE_CLIENT_AUTHORIZATION_FILE";
export const DELETE_CLIENT_AUTHORIZATION_FILE_SUCCESS =
   "DELETE_CLIENT_AUTHORIZATION_FILE_SUCCESS";

/**get Auth Service Mo Check */
export const GET_CLIENT_AUTHORIZATION_MOD_CHECK = "GET_CLIENT_AUTHORIZATION_MOD_CHECK";

/**add Files to Client Auth */
export const ADD_FILES_TO_CLIENT_AUTH = "ADD_FILES_TO_CLIENT_AUTH";

/**remove Files from Client Auth */
export const REMOVE_FILES_FROM_CLIENT_AUTH = "REMOVE_FILES_FROM_CLIENT_AUTH";

/**edit File Name of Client Auth */
export const EDIT_FILE_NAME_OF_CLIENT_AUTH = "EDIT_FILE_NAME_OF_CLIENT_AUTH";

/** Client Documents */
export const UPLOAD_CLIENT_DOCUMENT = "UPLOAD_CLIENT_DOCUMENT";
export const EDIT_CLIENT_DOCUMENT = "EDIT_CLIENT_DOCUMENT";
export const GET_CLIENT_DOCUMENT = "GET_CLIENT_DOCUMENT";
export const GET_CLIENT_DOCUMENT_SUCCESS = "GET_CLIENT_DOCUMENT_SUCCESS";
export const DELETE_CLIENT_DOCUMENT = "DELETE_CLIENT_DOCUMENT";

/** Client Address */
export const ADD_CLIENT_ADDRESS = "ADD_CLIENT_ADDRESS";
export const EDIT_CLIENT_ADDRESS = "EDIT_CLIENT_ADDRESS";
export const DELETE_CLIENT_ADDRESS = "DELETE_CLIENT_ADDRESS";

/** Staff Assignment */
export const ASSIGN_STAFF = "ASSIGN_STAFF";
export const EDIT_ASSIGNED_STAFF = "EDIT_ASSIGNED_STAFF";
export const GET_ASSIGNED_STAFFS = "GET_ASSIGNED_STAFFS";
export const GET_ASSIGNED_STAFFS_SUCCESS = "GET_ASSIGNED_STAFFS_SUCCESS";
export const REMOVE_STAFF = "REMOVE_STAFF";

