import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SelectTypeAutocomplete } from 'components';
import { renderClientName } from 'utils';

const ACTION_TYPE = 'GET_CLIENT_CONTACTS';
export const GuardianSelector = ({ setSelectedGuardian, selectedGuardian }) => {
    const { clientContacts, appt } = useSelector((state) => ({
        clientContacts: state.client.clientContacts,
        appt: state.appointment.appointmentById,
    }));

    useEffect(() => {
        if (clientContacts?.length && !appt?.signorId) {
            const defaultGuard = clientContacts?.find((i) => i?.legalGuardian);
            if (defaultGuard) {
                setSelectedGuardian(defaultGuard?.id);
            }
        }
        if (appt?.signorId) {
            setSelectedGuardian(appt?.signorId);
        }
    }, [clientContacts, appt]);

    const handleSelect = (e) => {
        setSelectedGuardian(e.target.value);
    };

    return (
        <div style={{ marginTop: 12 }}>
            <SelectTypeAutocomplete
                loadType={ACTION_TYPE}
                title={'Client Guardian'}
                name={'guardian'}
                handleSelect={handleSelect}
                defaultValue={selectedGuardian}
                list={clientContacts || []}
                renderValue={(i) => renderClientName(i)}
            />
        </div>
    );
};