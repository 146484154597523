import React from 'react';
import { APPT_ENUMS, formatMiles, renderStaffName } from 'utils';
import { DateRow, DetailItem,  } from 'components';

export const OtherItems = ({ item }) => {
    return (
        <>
            <div className="full-width">
                {item?.staff &&
                    <DetailItem
                        title={'Staff'}
                        value={renderStaffName(item?.staff)}
                    />
                }
                {item?.payCodeTypeName &&
                    <DetailItem
                        title={'Staff Paycode'}
                        value={item?.payCodeTypeName ? `${item.payCodeTypeName} ${item?.payCodeTypeCode ? `(${item?.payCodeTypeCode})` : '' }` : null}
                    />
                }
                {item?.type === APPT_ENUMS.DRIVE &&
                    <DetailItem
                        title={'Miles'}
                        value={formatMiles(item?.miles)}
                    />
                }
                <DetailItem
                    title={'Created Date'}
                    value={item?.createdDate ? <DateRow date={item?.createdDate} /> : 'N/A'}
                />
                <DetailItem
                    title={'Created By'}
                    value={item?.creatorFullName ? item.creatorFullName : 'N/A'}
                />
                <DetailItem
                    title={'Last Modified Date'}
                    value={item?.editedDate ? <DateRow date={item?.editedDate} /> : 'N/A'}
                />
                <DetailItem
                    title={'Last Modified By'}
                    value={item?.editorFullName ? item?.editorFullName : 'N/A'}
                />
            </div>
        </>
    );
};