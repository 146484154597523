import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadFile, FindSuccessItem, getFileType, Images, renderSize } from 'utils';
import { CustomDelete, MinLoader, SimpleModal, TextRow } from 'components';
import { appointmentActions, httpRequestsOnSuccessActions } from 'store';
import { signatureTypeEnums } from '../modals/constants';

const DELETE_ACTION_TYPE = 'DELETE_SIGNATURE';

export const UploadedSignature = ({ currentFile, alreadyUploaded, handleRemoveUploaded, provider }) => {
    const { appt } = useSelector((state) => ({
        appt: state.appointment.appointmentById,
    }));
    const { loadDownload, handleDownload } = DownloadFile();
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const type = provider ? signatureTypeEnums.PROVIDER_SIGNATURE : signatureTypeEnums.SIGNATURE;
    const success = FindSuccessItem(DELETE_ACTION_TYPE);
    const ifPdf = alreadyUploaded && currentFile?.file?.originalName?.toLowerCase().includes(".pdf")

    useEffect(() => {
        if (success) {
            setOpen(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION_TYPE));
        }
    },[success]);

    const deleteUploaded = () => {
        if (alreadyUploaded) {
            setOpen(true);
        } else {
            handleRemoveUploaded && handleRemoveUploaded();
        }
    };

    const closeModal = () => {
      setOpen(false)
    }

    if (!currentFile) {
        return null;
    }

    return (
        <>
            <div style={{ height: 90 }}>
                <div className="uploaded-signature-box" style={{}}>
                    <img
                        className="uploaded-signature-image"
                        src={alreadyUploaded && !ifPdf ? currentFile?.file?.url : Images.signatureIcon} alt="icon" />
                    <div className="full-width">
                        <div className="full-width">
                            <div className="space-between-align-start">
                                <p className="uploaded-signature-name" style={{ width: 230 }}>
                                    <TextRow name={alreadyUploaded ? currentFile?.file?.originalName : currentFile?.file?.name} />
                                </p>
                                <div className="flex-align-center" style={{ gap: 10, height: 20 }}>
                                    {alreadyUploaded &&
                                        <button className="reset-btn-no-paddings"
                                                onClick={() => handleDownload(currentFile?.file)}>
                                            {loadDownload ?
                                                <MinLoader
                                                    small={true}
                                                    margin={'0'} color={'#172B4D'} position={'relative'} />
                                                :
                                                <img style={{
                                                    height: 20,
                                                    filter: 'invert(11%) sepia(82%) saturate(1066%) hue-rotate(194deg) brightness(90%) contrast(92%)',
                                                }} src={Images.downloadCloudBlue} alt={'download'} />
                                            }
                                        </button>
                                    }
                                    {alreadyUploaded &&
                                        <a href={currentFile?.file?.url} target={'_blank'}>
                                            <img style={{ height: 20 }} src={Images.showEye} alt={'view'} />
                                        </a>
                                    }
                                    <button className="reset-btn-no-paddings" onClick={deleteUploaded}>
                                        <img style={{ height: 20 }} src={Images.redTrash} alt={'icon'} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex-align-center uploaded-signature-information"
                             style={{ margin: '4px 0' }}>
                            <p>{alreadyUploaded ? getFileType(currentFile?.file?.originalName) : currentFile?.file?.type?.replace('image/', '')}</p>
                            {!alreadyUploaded && <p>{renderSize(currentFile?.file)}</p>}
                        </div>

                        {!alreadyUploaded &&
                            <div className="full-loaded">
                                <div className="full-loaded-line" />
                                <p>100%</p>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <SimpleModal
                handleOpenClose={closeModal}
                openDefault={open}
                content={
                    <div className="modal-wrapper-style">
                        <div className="modal-body-style">
                            <CustomDelete
                                text={`Are you sure you want to delete this signature?`}
                                info={`Delete ${type === signatureTypeEnums.SIGNATURE ? 'Client' : 'Provider'} Signature?`}
                                handleDel={() => dispatch(appointmentActions.deleteSignature(appt?.id, type))}
                                handleClose={closeModal}
                                noClose={true}
                                actionType={DELETE_ACTION_TYPE}
                                body={
                                    <div className="deleting-client-info-box">
                                        <img src={Images.signatureRemove} alt={'credential'} />
                                        <p>{currentFile?.file?.originalName}</p>
                                    </div>
                                }
                            />
                        </div>
                    </div>
                }
            />
        </>
    );
};