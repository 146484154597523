import {makeStyles} from '@material-ui/core/styles';
import {Colors, Images, Shadow} from 'utils';

export const systemItemStyles = makeStyles(() => ({
    editServiceTypeWrapperStyle: {
        '& > button': {backgroundColor: Colors.BackgroundWater},
        '& > div': {
            textAlign: 'left',
            '&:first-of-type': {paddingBottom: '0'},
        },
    },
    credentialTable: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginTop: 16,
        position: 'relative',
        width: '100%',
        maxHeight: '50vh',
        overflow: 'auto',
    },
    item: {
        flex: '0 0 100%',
        width: '49%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: Colors.BackgroundWater,
        padding: '12px 32px',
        borderRadius: 8,
        marginBottom: 8,

        '& .text-style': {
            fontSize: 14,
            color: Colors.TextPrimary,
            lineHeight: '16px',
        },
        '& > p ': {
            fontSize: 14,
            color: Colors.TextPrimary,
            '& span': {
                fontWeight: 600,
                lineHeight: '16px',
            },
        },
    },
    text: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontWeight: '600',
            color: Colors.TextPrimary,
        },
    },
    title: {
        fontSize: 18,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
        marginTop: 10,
    },
    subTitle: {
        fontSize: 16,
        color: Colors.TextSecondary,
        marginTop: 8,
        lineHeight: '21px',
    },
    systemItemWrapper: {
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 8,
        position: 'relative',
    },
    systemHeaderStyles: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    spaceBottom: {
        marginBottom: 32,
    },
    systemIcon: {
        width: 32,
        height: 32,
    },
    systemTitle: {
        fontSize: 18,
        color: Colors.TextSecondary,
        fontWeight: 'bold',
        paddingLeft: 8,
    },
    flexContainer: {display: 'flex'},
    credentialInputStyle: {
        maxWidth: '387px',
        minWidth: '161px',
        width: '100%',
        marginRight: 16,
        '& .MuiOutlinedInput-root': {
            height: '36px!important',
            color: Colors.TextPrimary,
            fontSize: 14,
        },
    },
    codInputStyle: {
        minWidth: '150px',
        width: '100%',
        marginRight: 16,
        '& .MuiOutlinedInput-root': {
            height: '36px!important',
            color: Colors.TextPrimary,
            fontSize: 14,
        },
    },

    headerSize: {
        width: '49%',
    },
    icons: {
        cursor: 'pointer',
        display: 'flex',
        '& img:last-child': {
            marginLeft: 16,
        },
    },
    // Payroll Setup
    tabContainer: {
        display: 'inline-block',
        backgroundColor: 'white',
        boxShadow: Shadow.noteModalShadow,
        lineHeight: 1,
        borderRadius: 8,
    },
    activeStepText: {
        backgroundColor: Colors.BackgroundBlue,
        borderRadius: 8,
        color: 'white!important',
    },
    stepText: {
        fontSize: 14,
        color: Colors.TextSecondary,
        fontWeight: 600,
        display: 'inline-block',
        verticalAlign: 'middle',
        padding: '9px 24px',
        cursor: 'pointer',
        margin: 2,
    },
    noTypeYet: {
        fontSize: 14,
        color: Colors.TextDarkGrey,
    },
    credentialNameTypeStyle: {
        '& > em': {fontWeight: 600},
    },
    loaderStyle: {
        minHeight: '496px',
        display: 'flex',
        alignItems: 'center',
    },
    credentialsFlexAble: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        minHeight: '60vh',
    },
    companyBox: {
        width: '600px',
    },
    companyBodyBox: {
        marginTop: '24px',
    },
    actionButtonsBox: {
        display: 'flex',
        alignItems: 'center',
        '& button': {
            background: 'transparent',
            border: 'none',
            padding: 0,
        },
        '& button:last-of-type': {
            marginLeft: '8px',
            width: '30px',
        },
    },
    currentLogo: {
        width: '80px',
        height: '80px',
        borderRadius: '8px',
        objectFit: 'contain',
    },
    uploadedImage: {
        position: 'relative',

        '& button': {
            position: 'absolute',
            background: 'transparent',
            border: 'none',
            right: '-17px',
            top: '-12px',
            '& img': {
                marginBottom: 0,
            },
        },
    },
    calendarBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #E4E7EC',
        background: '#FFF',
        padding: '16px',
    },

    connectBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '32px',
        width: '90px',
        padding: '10px 16px',
        borderRadius: '4px',
        background: Colors.BackgroundBlue,
        color: Colors.TextWhite,
        fontSize: '14px',
        fontWeight: '600',
        border: 'none',
    },

    removeBtn: {
        background: 'transparent',
        border: 'none',
        marginLeft: '6px',
        marginTop: '2px',
    },

    iconAndTitle: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            color: Colors.TextSecondary,
            fontSize: '16px',
            fontWeight: '600',
            marginLeft: '16px',
        },

    },
    serviceInfoBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',

        '& p':{
            color: '#4B5C68',
            fontSize: '14px',
            fontWeight: '600',
        },

        '& span':{
            color: '#4B5C68',
            fontSize: '14px',
            fontWeight: '400',
        }
    },


    tablePermissionsBodyStyleWrapper: {
        position: 'relative',
        minHeight: '48px'
    },
    tablePermissionsBodyStyle: {
        // position: 'absolute',
        padding: '0 0 0',
        width:'100%',
        '& .MuiAutocomplete-paper': {
            borderRight: '1px solid rgba(163, 178, 189, 0.30)',
            borderLeft: '1px solid rgba(163, 178, 189, 0.30)',
            borderTop: '1px solid rgba(163, 178, 189, 0.30)',
            borderRadius: '4px 4px 0 0',
            margin: 0,
        },
        '& .MuiPaper-elevation1': {
            boxShadow: 'none',
            // position: 'absolute',
            width :'100%'
        },

        '& .MuiAutocomplete-option': {
            padding: '4px 7px',
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
            background: '#EBF2FD!important',
        },
        '& .MuiAutocomplete-option[data-focus="true"]': {
            background: '#EBF2FD!important',
        },
    },
    serviceBodyItems:{
        width:'100%',
        height : '100%',
        maxHeight: '60vh',
        overflowY: 'auto',
    }

}));
