import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SimpleTabs } from 'components';
import { SIGNATURE_ACTION_TYPE, signatureTabsLabels } from './constants';
import { Uploader } from './uploader';
import { Draw } from './draw';
import { FindSuccessItem, formatAMPMNeW } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';
import moment from 'moment';

const SUCCESS_TYPE = SIGNATURE_ACTION_TYPE;

const tabEnums = {
    DRAW: 'Draw',
    UPLOAD: 'Upload',
};
export const SignatureUploader = ({}) => {
    const { appt } = useSelector((state) => ({
        appt: state.appointment.appointmentById,
    }));
    const [clientTab, setClient] = useState(tabEnums.DRAW);
    const [providerTab, setProviderTab] = useState(tabEnums.DRAW);
    const dispatch = useDispatch();
    const success = FindSuccessItem(SUCCESS_TYPE);

    useEffect(() => {
        if (success?.type === SUCCESS_TYPE) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(SUCCESS_TYPE));
        }
    }, [success]);

    const tabsContent = [
        {
            tabComponent: <Draw appt={appt} />,
            tab: 'Draw',
        },
        {
            tabComponent: <Uploader appt={appt} />,
            tab: 'Upload',
        },
    ];

    const providerTabsContent = [
        {
            tabComponent: <Draw appt={appt} provider={true} />,
            tab: 'Draw',
        },
        {
            tabComponent: <Uploader appt={appt} provider={true} />,
            tab: 'Upload',
        },

    ];

    return (
        <div className="signature-tabs-wrapper">
            <p className="modal-header-title">Signatures</p>
            <div className="signature-modal-info-box">
                <div className="appt-detail-box">
                    <p className="appt-detail-modal-title">Appt ID:</p>
                    <p className="appt-detail-modal-value">{appt?.displayId}</p>
                </div>
                <div className="appt-detail-box">
                    <p className="appt-detail-modal-title">Date/Time:</p>
                    <p className="appt-detail-modal-value">
                        <div className="flex-align-center" style={{ gap: '8px' }}>
                            <p>{moment.utc(appt?.startDate).format('MM/DD/YYYY')}</p>
                            <p className="schedule-time">
                                {`${formatAMPMNeW(appt?.startTime)} - ${formatAMPMNeW(appt?.endTime)}`}
                            </p>
                        </div>
                    </p>
                </div>
                <div className="appt-detail-box">
                    <p className="appt-detail-modal-title">CPT Code: </p>
                    <p className="appt-detail-modal-value">{appt?.authorizedService?.service?.cptCode}</p>
                </div>
            </div>

            <div className="modal-body-wrapper flex-align-start" style={{ gap: 32 }}>
                <div style={{ width: 380 }}>
                    <p className="signature-type-title">Client/Guardian Signature</p>
                    <SimpleTabs
                        small={true}
                        getTab={setClient}
                        tab={clientTab}
                        defaultTab={tabEnums.DRAW}
                        tabsLabels={signatureTabsLabels}
                        tabsContent={tabsContent}
                    />
                </div>
                <div style={{ width: 380 }}>
                    <p className="signature-type-title">Provider Signature</p>
                    <SimpleTabs
                        small={true}
                        getTab={setProviderTab}
                        tab={providerTab}
                        defaultTab={tabEnums.DRAW}
                        tabsLabels={signatureTabsLabels}
                        tabsContent={providerTabsContent}
                    />
                </div>
            </div>
        </div>
    );
};