import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CreateChancel } from 'components';
import { appointmentActions, httpRequestsOnSuccessActions, UploadServices } from 'store';
import { base64ToFile, FindLoad, FindSuccessItem, Images } from 'utils';
import SignatureCanvas from 'react-signature-canvas';
import { scheduleModalsStyle } from '../modals';
import { UploadedSignature } from './uploadedSignature';
import { GuardianSelector } from './guardianSelector';
import { signatureTypeEnums } from '../modals/constants';
import { SIGNATURE_ACTION_TYPE } from './constants';

const ACTION_TYPE = SIGNATURE_ACTION_TYPE;

export const Draw = ({ appt, provider }) => {
    // const classes = scheduleModalsStyle();
    // const [position, setPosition] = useState(true);
    const dispatch = useDispatch();
    const [uploadLoader, setUploadLoader] = useState(false);
    const loader = FindLoad(ACTION_TYPE);
    const success = FindSuccessItem(ACTION_TYPE);
    const [geoPos, setCoords] = useState({});
    const [isDrawing, setIsDrawing] = useState(false);
    const sigCanvas = useRef(null);
    const [currentFile, setCurrentFile] = useState(null);
    const [selectedGuardian, setSelectedGuardian] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(true);
    }, []);

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            clearSignature();
        }
    }, [success]);

    useEffect(() => {
        if (provider) {
            if (appt?.providerSignature?._id) {
                setCurrentFile({ file: appt.providerSignature });
            } else {
                setCurrentFile(null);
            }
        } else {
            if (appt?.signature?._id) {
                setCurrentFile({ file: appt.signature });
            } else {
                setCurrentFile(null);
            }
        }
    }, [appt]);


    useEffect(() => {
        if (!isOpen) return;

        if (!navigator.geolocation) {
            console.error('Geolocation is not supported by your browser.');
            return;
        }

        const watchId = navigator.geolocation.watchPosition(
            (pos) => {
                // setPosition(true);
                setCoords({
                    lat: pos.coords.latitude,
                    lng: pos.coords.longitude,
                });
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    // setPosition(false);
                    setCoords(null);
                }
            },
        );

        return () => {
            navigator.geolocation.clearWatch(watchId);
        };
    }, [isOpen]);

    const getSignature = async () => {
        const signatureData = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        if (sigCanvas?.current && signatureData && isDrawing) {
            const convertedFile = base64ToFile(signatureData, 'signature.png');
            if (convertedFile) {
                let formData = new FormData();
                formData.append('files', convertedFile);
                try {
                    setUploadLoader(true);
                    const res = await UploadServices.UploadImageThumb(formData);
                    const info = {
                        file: {
                            ...res?.data,
                            name: provider ? 'provider-signature' : 'signature',
                        },
                        signatureType: provider ? signatureTypeEnums.PROVIDER_SIGNATURE : signatureTypeEnums.SIGNATURE,
                    };
                    if (!provider) {
                        selectedGuardian ? info.signor = selectedGuardian : null;
                    }
                    dispatch(appointmentActions.appendSignatureToAppmt(appt.id, {
                        ...info,
                        signatureLocation: geoPos ? { ...geoPos } : null,
                    }));
                    setUploadLoader(false);
                } catch (e) {
                    setUploadLoader(false);
                }
            }
        }
    };

    const clearSignature = () => {
        if (sigCanvas?.current) {
            sigCanvas.current.clear();
            setIsDrawing(false);
        }
    };

    const handleMouseDown = () => {
        setIsDrawing(true);
    };

    return (
        <div>
            {/*<div>*/}
            {/*{position === false ?*/}
            {/*    <div className={classes.warningBox}>*/}
            {/*        <div className={classes.warning}>*/}
            {/*            <img src={Images.warning} alt="icon" />*/}
            {/*            <div>*/}
            {/*                <p>*/}
            {/*                    To draw a signature, please allow access to your location*/}
            {/*                </p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    :*/}
            <div>
                <div className="canvas-wrapper">
                    <SignatureCanvas
                        ref={sigCanvas}
                        penColor="black"
                        canvasProps={{ width: 480, height: 100, className: 'sigCanvas' }}
                        onBegin={handleMouseDown}
                    />
                    <div className="canvas-line" />
                    <div className="clear-canvas-btn">
                        <button onClick={clearSignature}>
                            <img src={Images.clearSignature} alt="icon" />
                            <p>Clear</p>
                        </button>
                    </div>
                </div>
                <p className="draw-info-text">
                    By signing this document with an electronic signature, I agree to
                    the terms and conditions outlined by the clinic.
                </p>
            </div>
            {/*}*/}
            {/*</div>*/}

            <UploadedSignature
                currentFile={currentFile}
                alreadyUploaded={true}
                provider={provider}
            />

            {!provider && (isDrawing ? true : appt?.signature ? appt?.signorId : true) &&
                <GuardianSelector
                    setSelectedGuardian={setSelectedGuardian}
                    selectedGuardian={selectedGuardian}
                />
            }
            {isDrawing &&
                <div style={{ marginTop: 24 }}>
                    <CreateChancel
                        height={'36px'}
                        noChancel={true}
                        loader={uploadLoader || !!loader?.length}
                        create={'Submit'}
                        onCreate={getSignature}
                        buttonWidth="100px"
                        justifyContent={'flex-end'}

                    />
                </div>
            }
        </div>
    );
};