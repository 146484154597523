import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
    CreateChancel,
    SimpleTooltip,
    SelectAutocompleteMultiple,
    DateCalendarForm,
    SelectAutocompletePagination
} from 'components';
import { FindLoad, FindSuccess, renderStaffName, useModal } from 'utils';
import { adminActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { createClientStyle } from 'fragments/client';
import { FindErrorItem } from 'utils/hooks/findError';
import { EMPLOYMENT_TYPES } from '../constants';
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';

const JOB_ACTIONS = 'GET_JOBS';
const DEPARTMENT_ACTIONS = 'GET_DEPARTMENTS';
export const EmploymentModal = ({ edit }) => {
    const { staffGeneral } = useSelector((state) => ({
        staffGeneral: state.admins.adminInfoById,
    }));
    const activeEmployment = staffGeneral?.employment;
    const { close } = useModal();
    const ACTION_TYPE = edit ? 'EDIT_EMPLOYMENT' : 'CREATE_EMPLOYMENT';
    const classes = createClientStyle();
    const dispatch = useDispatch();
    const jobTitles = useSelector((state) => state.system.jobs);
    const departments = useSelector((state) => state.system.departments);
    const success = FindSuccess(ACTION_TYPE);
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindErrorItem(ACTION_TYPE);
    const [active, setActive] = useState(false);
    const { handleSubmit, control, clearErrors, setError, reset, watch } = useForm({});
    const [inputs, setInputs] = useState({});

    useEffect(() => {
        dispatch(systemActions.getJobs());
        dispatch(systemActions.getDepartments());
    }, []);

    useEffect(() => {
        if (edit?.id) {
            const params = {
                ...edit,
            };
            if (params?.id) delete params?.id;
            if (params?.schedule) delete params?.schedule;

            params.startDate = moment.utc(edit?.startDate).format('YYYY-MM-DD');
            params.endDate = edit?.endDate ? moment.utc(edit?.endDate).format('YYYY-MM-DD') : null;
            params.departmentId = edit?.department?._id;
            params.supervisor = edit?.supervisor?._id;
            params.title = edit?.title?._id;
            reset(params);
            if (edit?.supervisor?._id) {
                setInputs({ ...inputs, supervisor: edit?.supervisor?._id });
            }
            setActive(edit?.active !== true);
        } else {
            setActive(!!activeEmployment?.id);
        }
    }, [edit]);

    useEffect(() => {
        if (backError?.error?.length) {
            if (backError?.error === 'employment overlapping') {
                setError('startDate', {
                    message: 'Employments cannot overlap, please change the date!',
                });
            }
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [backError]);

    useEffect(() => {
        if (!!success?.length) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        return () => {
            clearErrors();
            dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        };
    }, []);

    function handleCreate(data) {
        const sendParams = {
            ...data,
            title: data?.title,
            departmentId: data?.departmentId,
            type: data?.type,
            active: !active,
        };

        inputs?.supervisor ? sendParams.supervisor = inputs?.supervisor : delete sendParams.supervisor
        delete sendParams?.department;
        if (data?.endDate) {
            moment.utc(data.endDate).format('YYYY-MM-DD');
        } else {
            sendParams.endDate = null;
        }

        if (edit) {
            dispatch(adminActions.editEmployment(sendParams, edit?.id, staffGeneral?.id));
        } else {
            dispatch(adminActions.createEmployment(sendParams, staffGeneral?.id));
        }
    }

    const handleChange = (e) => {
        setInputs({ ...inputs, [e.target.name]: e.target.value });
    };

    return (
        <form
            className="employment-modal"
            onSubmit={handleSubmit(handleCreate)}
        >
            <p className="modal-header-title">{edit ? 'Edit Employment' : 'Add Employment'}</p>
            <div className="modal-body-wrapper">
                <SelectAutocompleteMultiple
                    defaultSelected={edit?.title && { name: edit?.title?.name }}
                    name="title"
                    label={'Title*'}
                    control={control}
                    options={jobTitles}
                    loadType={JOB_ACTIONS}
                    rules={{
                        required: 'Title is required.',
                    }}
                    renderValue={(option) => option?.name}
                />
                <SelectAutocompletePagination
                    uiType={'fromForm'}
                    title={'Supervisor'}
                    name={'supervisor'}
                    handleSelect={handleChange}
                    defaultValue={inputs?.supervisor}
                    renderValue={(i) => renderStaffName(i)}
                    type={'staff'}
                    currentSelected={edit?.supervisor && {
                        firstName: edit?.supervisor?.firstName,
                        lastName: edit?.supervisor?.lastName,
                        middleName: edit?.supervisor?.middleName,
                    }}
                />
                <SelectAutocompleteMultiple
                    defaultSelected={edit?.department && { name: edit?.department?.name }}
                    name="departmentId"
                    label={'Department*'}
                    control={control}
                    options={departments}
                    loadType={DEPARTMENT_ACTIONS}
                    rules={{
                        required: 'Department is required.',
                    }}
                    renderValue={(option) => option?.name}
                />
                <SelectAutocompleteMultiple
                    name="type"
                    label={'Employment Type*'}
                    control={control}
                    options={EMPLOYMENT_TYPES}
                    loadType={DEPARTMENT_ACTIONS}
                    rules={{
                        required: 'Employment is required.',
                    }}
                    renderValue={((i) => i?.name)}
                />
                <div style={{ display: 'flex', gap: '16px' }}>
                    <DateCalendarForm
                        name="startDate"
                        label={'Start Date*'}
                        max={watch('endDate') ? moment.utc(watch('endDate')).format('YYYY-MM-DD') : ''}
                        control={control}
                        rules={{ required: 'Start date is required.' }}
                    />
                    <DateCalendarForm
                        name="endDate"
                        label={`End Date${active ? '*' : ''}`}
                        min={watch('startDate') ? moment.utc(watch('startDate')).format('YYYY-MM-DD') : ''}
                        control={control}
                        rules={{ required: active ? 'End date is required.' : false }}
                        disabled={!watch('startDate')}
                    />
                </div>
                <SimpleTooltip
                    title={!!activeEmployment ?
                        <p className={classes.infoTextForAuthStyle}>
                            There is already an active employment for this staff. Inactivate it to set this one
                            active
                        </p> : ''
                    }
                    placement="top-start"
                >
                    <FormGroup style={{ marginBottom: '20px' }}>
                        <FormControlLabel
                            style={{ opacity: !!activeEmployment && !edit ? '0.7' : 1, width: 'fit-content' }}
                            disabled={activeEmployment?.id && !edit}
                            control={
                                <Checkbox
                                    onChange={(e) => {
                                        setActive(e?.target?.checked);
                                        clearErrors('endDate');
                                    }}
                                    style={{ color: '#347AF0' }}
                                    value={active === false}
                                    checked={!!active}
                                />
                            } label="Past Employment"
                        />
                    </FormGroup>
                </SimpleTooltip>
            </div>

            <CreateChancel
                type="submit"
                loader={!!loader.length}
                create={edit ? 'Save' : 'Add'}
                chancel={'Cancel'}
                onClose={close}
                buttonWidth="224px"
            />
        </form>
    );
};
